import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum siz
import KeyForm from "./pages/S4/KeyForm";
import ReportingUser from "./pages/S4/ReportingUser";
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import DocLayout from "./layouts/Doc";
import NoneHeader from "./layouts/NoneHeader";
import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ConfirmPassword from "./components/auth/ConfirmPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";

// Components
import Accordion from "./pages/components/Accordion";
import Alerts from "./pages/components/Alerts";
import Avatars from "./pages/components/Avatars";
import Badges from "./pages/components/Badges";
import Buttons from "./pages/components/Buttons";
import Cards from "./pages/components/Cards";
import Chips from "./pages/components/Chips";
import Dialogs from "./pages/components/Dialogs";
import Lists from "./pages/components/Lists";
import Menus from "./pages/components/Menus";
import Pagination from "./pages/components/Pagination";
import Progress from "./pages/components/Progress";
import Snackbars from "./pages/components/Snackbars";
import Tooltips from "./pages/components/Tooltips";

// Form components
import SelectionCtrls from "./pages/forms/SelectionControls";
import Selects from "./pages/forms/Selects";
import TextFields from "./pages/forms/TextFields";
import AddUser from "./pages/users/AddUser";

// Icon components
import MaterialIcons from "./pages/icons/MaterialIcons";

// Page components
import Blank from "./pages/pages/Blank";
import InvoiceDetails from "./pages/pages/InvoiceDetails";
import InvoiceList from "./pages/pages/InvoiceList";
import Orders from "./pages/pages/Orders";
import Pricing from "./pages/pages/Pricing";
import Settings from "./pages/pages/Settings";
import Projects from "./pages/pages/Projects";
import Chat from "./pages/pages/Chat";
import ViewUser from "./pages/pages/ViewUser";

// Table components
import SimpleTable from "./pages/tables/SimpleTable";
import AdvancedTable from "./pages/tables/AdvancedTable";
import Users from "./pages/users/Users";

import UserOne from "./pages/tables/UserOne";
import PropertyList from "./pages/accounts/PropertyList";
import ListCorporate from "./pages/accounts/ListCorporate";
import FloorPlans from "./pages/accounts/FloorPlans";
import AddProperty from "./pages/accounts/AddProperty";
import AddCorporate from "./pages/accounts/AddCorporate";
import EditProperty from "./pages/accounts/EditProperty";
import EditCorporate from "./pages/accounts/EditCorporate";

// Equipment components
import EquipmentList from "./pages/equipment/EquipmentList";
import AddEquipment from "./pages/equipment/AddEquipment";
import EditEquipmentUser from "./pages/equipment/EditEquipmentUser";
import EditEquipment from "./pages/equipment/EditEquipment";
import EquipmentUserList from "./pages/equipment/EquipmentUserList";
import AddEquipmentUser from "./pages/equipment/AddEquipmentUser";
import ProcessEquipment from "./pages/equipment/ProcessEquipment";
import EquipmentLogs from "./pages/equipment/EquipmentLogs";
import PositionsList from "./pages/equipment/PositionsList";
import AddPositions from "./pages/equipment/AddPositions";
import EditPosition from "./pages/equipment/EditPosition";
import DepartmentList from "./pages/equipment/DepartmentList";
import AddDepartment from "./pages/equipment/AddDepartment";
import EquipmentDetail from "./pages/equipment/EquipmentDetail";
import ViewEquipmentDetail from "./pages/equipment/ViewEquipmentDetail";
import ScreeningSurvey from "./pages/logs/ScreeningSurvey";
import SurveyDone from "./pages/logs/SurveyDone";

// Tour Component
import TourListing from "./pages/tours/TourListing";
import AddTour from "./pages/tours/AddTour";
import EditTour from "./pages/tours/EditTour";
import GeoLocate from "./pages/tours/GeoLocate";
import AddTourBuilding from "./pages/tours/AddTourBuilding";
import CheckPoints from "./pages/tours/CheckPoints";
import EditButton from "./pages/tours/EditButton";

// Task Component
import ListTasks from "./pages/tasks/ListTasks";
import AddTask from "./pages/tasks/AddTask";

// Visitor component
import AddVisitor from "./pages/visitors/AddVisitor";
import Companies from "./pages/visitors/Companies";
import AddCompany from "./pages/visitors/AddCompany";
import KioskMode from "./pages/visitors/KioskMode";
import KioskSettings from "./pages/visitors/KioskSettings";
import ListVisitors from "./pages/visitors/listing/ListVisitors";
import ProcessVisitor from "./pages/visitors/ProcessVisitor";
import VisitLog from "./pages/visitors/VisitLog";
import VisitorDashboard from "./pages/visitors/VisitorDashboard";
import VisitorTypes from "./pages/visitors/VisitorTypes";
import VisitorWatchList from "./pages/visitors/VisitorWatchList";
import ViewVisitorWatch from "./pages/visitors/ViewVisitorWatch";
import EditVisitorWatch from "./pages/visitors/EditVisitorWatch";
import AddVisitorWatch from "./pages/visitors/AddVisitorWatch";
import CheckIn from "./pages/visitors/CheckIn";
import ReturningVisitorInfo from "./pages/visitors/ReturningVisitorInfo";
import ReturningVisitors from "./pages/visitors/ReturningVisitors";
import VisitorDetail from "./pages/visitors/VisitorDetail";
import KioskSignOut from "./pages/visitors/KioskSignOut";
import ScanQrCode from "./pages/visitors/ScanQrCode";
import PackagesDelivery from "./pages/visitors/PackagesDelivery";
import KioskModeCheckIn from "./pages/visitors/KioskModeCheckIn";
import ConfirmReturningVisitors from "./pages/visitors/ConfirmReturningVisitors";
// import BadgePrint from "./pages/visitors/inner-component/BadgePrint";

// Employee Accidents
import EmployeeAccidents from "./pages/EmployeeAccidents";
import ViewEmployeeAccident from "./pages/ViewEmployeeAccident";
import EditEmployeeAccident from "./pages/EditEmployeeAccident";
import AddEarReports from "./pages/AddEarReports";
import AddEar from "./pages/AddEar";
import EditEarReports from "./pages/EditEarReports";
//Live Module
import LiveModule from "./pages/livemodule/LiveModule";

// Lost and Found
import ListLostAndFound from "./pages/lostandfound/ListLostAndFound";
import AddLostAndFound from "./pages/lostandfound/AddLostAndFound";
import AddFound from "./pages/lostandfound/AddFound";
import ImageSearch from "./pages/lostandfound/ImageSearch";
import Shipments from "./pages/lostandfound/Shipments";
import ShipmentsAdmin from "./pages/lostandfound/ShipmentsAdmin";
import ViewActivityLogs from "./pages/lostandfound/ViewActivityLogs";
import MatchLostFound from "./pages/lostandfound/MatchLostFound";
import EditLostAndFound from "./pages/lostandfound/EditLostAndFound";
import LostandfoundDetails from "./pages/lostandfound/LostandfoundDetails";
import BagTagPdf from "./pages/lostandfound/BagTagPdf";
import TransactionDetails from "./pages/lostandfound/TransactionDetails";
import PaymentDone from "./pages/lostandfound/PaymentDone";
import ProcessToShipment from "./pages/lostandfound/ProcessToShipment";
import UserReceipt from "./pages/lostandfound/UserReceipt";
import LostFoundImageSearch from "./pages/lostandfound/LostFoundImageSearch";

// Permissions page
import EditGroupPermissions from "./pages/permissions/EditGroupPermissions";
import UserRole from "./pages/permissions/UserRole";

// Reports page
import NonTourTasks from "./pages/reports/NonTourTasks";
import DailyActivity from "./pages/reports/DailyActivity";
import Performance from "./pages/reports/Performance";
import Incidents from "./pages/reports/Incidents";
import AddIncidentReport from "./pages/reports/AddIncidentReport";
import EditIncidentReport from "./pages/reports/EditIncidentReport";
import ViewIncidentReport from "./pages/reports/ViewIncidentReport";
import SendIrLogs from "./pages/reports/SendIrLogs";
import TourPatrol from "./pages/reports/TourPatrol";
import TourSummary from "./pages/reports/TourSummary";
import TourReport from "./pages/reports/TourReport";
import Passdown from "./pages/reports/Passdown";
import ButtonHistory from "./pages/reports/ButtonHistory";
import MissedButton from "./pages/reports/MissedButton";
import DeviceReports from "./pages/reports/DeviceReports";
import SmsReports from "./pages/reports/SmsReports";
// Person of interest
import PoiDashboard from "./pages/poi/PoiDashboard";
import PoiList from "./pages/poi/PoiList";
import AddPersonOfInterest from "./pages/poi/AddPersonOfInterest";
import PoiEncounters from "./pages/poi/PoiEncounters";
import PoiImageSearch from "./pages/poi/PoiImageSearch";
import ViewPoi from "./pages/poi/ViewPoi";
import EditPoi from "./pages/poi/EditPoi";
import ViewPoiEncounter from "./pages/poi/ViewPoiEncounter";
import AddPoiEncounter from "./pages/poi/AddPoiEncounter";
import EditPoiEncounter from "./pages/poi/EditPoiEncounter";
// Packages
import ListPackages from "./pages/packages/ListPackages";
import AddPackage from "./pages/packages/AddPackage";
import ViewPackage from "./pages/packages/ViewPackage";
import EditPackage from "./pages/packages/EditPackage";
import BulkReceiving from "./pages/packages/BulkReceiving";
import PackageAudit from "./pages/packages/PackageAudit";

// Settings pages
import Communication from "./pages/settings/property-user/Communication";
import IrNumbering from "./pages/settings/corporate-user/IrNumbering";
import ListDepartment from "./pages/settings/corporate-user/ListDepartment";
import IrClassifications from "./pages/settings/corporate-user/IrClassifications";
import AddIrClassification from "./pages/settings/corporate-user/AddIrClassification";
import EditIrClassification from "./pages/settings/corporate-user/EditIrClassification";
import LostFoundPrivacy from "./pages/settings/corporate-user/LostFoundPrivacy";
import CustomizeReport from "./pages/settings/corporate-user/CustomizeReport";
import TriggerWords from "./pages/settings/corporate-user/TriggerWords";
import IpRange from "./pages/settings/corporate-user/IpRange";
import LostAndFoundRule from "./pages/settings/corporate-user/LostAndFoundRule";
import ModifyNavMenu from "./pages/settings/corporate-user/ModifyNavMenu";
import ENotifyiTempletes from "./pages/settings/corporate-user/ENotifyiTempletes";
import AddQuestion from "./pages/settings/corporate-user/AddQuestion";
import LoginRecords from "./pages/settings/corporate-user/LoginRecords";
import ListDevice from "./pages/settings/corporate-user/ListDevice";
import ModuleColor from "./pages/settings/corporate-user/ModuleColor";
import NotifyDiv from "./pages/settings/corporate-user/NotifyDiv";
import SecurityEvents from "./pages/settings/corporate-user/SecurityEvents";
import PastNotify from "./pages/settings/corporate-user/PastNotify";
import CarrierSettings from "./pages/settings/corporate-user/CarrierSettings";
import Permission from "./pages/settings/corporate-user/Permission";
import SwitchDummyData from "./pages/settings/corporate-user/SwitchDummyData";
import UserSearch from "./pages/settings/corporate-user/UserSearch";
import YoutubeVideos from "./pages/settings/corporate-user/YoutubeVideos";
import SmsReport from "./pages/settings/corporate-user/SmsReport";
import Venues from "./pages/settings/corporate-user/Venues";
import CarrierServiceLevel from "./pages/settings/corporate-user/CarrierServiceLevel";

// AED Management
import AedManagement from "./pages/logs/AedManagement";
import AddAedInspection from "./pages/logs/inner-component/AddAedInspection";
import EditAedInspection from "./pages/logs/inner-component/EditAedInspection";
import AlarmActivationLog from "./pages/logs/AlarmActivationLog";
import AddAlarmActivationLog from "./pages/logs/AddAlarmActivationLog";
import EditAlarmActivationLog from "./pages/logs/EditAlarmActivationLog";
import AttendanceLog from "./pages/logs/AttendanceLog";
import EmergencyProcedures from "./pages/logs/EmergencyProcedures";
import EditEmergencyProcedures from "./pages/logs/EditEmergencyProcedures";
import Covid19Survey from "./pages/logs/Covid19Survey";
import ViewCovid19Survey from "./pages/logs/ViewCovid19Survey";
import KeyRequisition from "./pages/logs/KeyRequisition";
import AddKeyRequisition from "./pages/logs/AddKeyRequisition";
import EditKeyRequisition from "./pages/logs/EditKeyRequisition";
import OverdueKeyReport from "./pages/logs/OverdueKeyReport";
import AddReport from "./pages/logs/AddReport";
import EditReport from "./pages/logs/EditReport";
import ViewLostBadgeReport from "./pages/logs/ViewLostBadgeReport";
import LockerLog from "./pages/logs/LockerLog";
import Lockers from "./pages/logs/Lockers";
import Locks from "./pages/logs/Locks";
import Employees from "./pages/logs/Employees";
import AddEmployee from "./pages/logs/AddEmployee";
import EditEmployee from "./pages/logs/EditEmployee";
import VehicleManagement from "./pages/logs/VehicleManagement";
import Violations from "./pages/logs/Violations";
import ShiftReport from "./pages/logs/ShiftReport";
import AddShiftReport from "./pages/logs/AddShiftReport";
import SpecialVisitors from "./pages/logs/SpecialVisitors";
import EditShiftReport from "./pages/logs/EditShiftReport";
// import SubmitBugReport from "./pages/pages/SubmitBugReport";

import VehicleInspection from "./pages/logs/VehicleInspection";
// Property Inspection
import PropertyInspection from "./pages/PropertyInspection";
import PropertyInspectionResultPdf from "./pages/property-inspection-inner-component/PropertyInspectionResultPdf";

// Schedule Calendar
import ScheduleCalendar from "./pages/schedules/ScheduleCalendar";

// Documentation
import Welcome from "./pages/docs/Welcome";
import GettingStarted from "./pages/docs/GettingStarted";
import Routing from "./pages/docs/Routing";
import Auth0 from "./pages/docs/auth/Auth0";
import Cognito from "./pages/docs/auth/Cognito";
import Firebase from "./pages/docs/auth/Firebase";
import JWT from "./pages/docs/auth/JWT";
import Guards from "./pages/docs/Guards";
import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
import Deployment from "./pages/docs/Deployment";
import Theming from "./pages/docs/Theming";
import APICalls from "./pages/docs/APICalls";
import Redux from "./pages/docs/Redux";
import Internationalization from "./pages/docs/Internationalization";
import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
import Support from "./pages/docs/Support";
import Changelog from "./pages/docs/Changelog";

// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import EditUser from "./pages/users/EditUser";
import EditTask from "./pages/tasks/EditTask";
import EditVisitor from "./pages/visitors/EditVisitor";
import EditCompany from "./pages/visitors/EditCompany";
import TenantList from "./pages/packages/TenantList";
import EditEar from "./pages/EditEar";
import LiveCastPics from "./pages/livemodule/LiveCastPics";
import UserVerification from "./pages/settings/property-user/UserVerification";
import Searchpage from "./components/navbar/Searchpage";
import BusinessChart from "./pages/businessintelligence/BusinessChart";
// import IrDashboard from "./pages/businessintelligence/IrDashboard";
import IrDashboard from "./pages/businessintelligence/IrDashboard";
import NewNotify from "./pages/NewNotify";
import EditNotify from "./pages/settings/corporate-user/EditNotify";
import ViewShiftReport from "./pages/logs/ViewShiftReport";
import AblyReceiver from "./components/Ably";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));
const Task = async(() => import("./pages/dashboards/Task"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const EditProfile = async(() => import("./pages/pages/EditProfile"));
// const Businesschart = async(() =>
//   import("./pages/businessintelligence/BusinessChart")
// );
// const Searchpages = async(() => import("./components/navbar/Searchpage"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Task />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
      {
        path: "saas",
        element: <SaaS />,
      },
      {
        path: "landing",
        element: <Task />,
      },
    ],
  },
  {
    path: "pages",
    element: <DashboardLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "new-notify",
        element: <NewNotify />,
      },
      // {
      //   path: "submit-bug-report",
      //   element: <SubmitBugReport />,
      // },

      {
        path: "edit-profile",
        element: <EditProfile />,
      },

      {
        path: "search-page/:searchInput",
        element: <Searchpage />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "pricing",
        element: <Pricing />,
      },
      {
        path: "chat",
        element: <Chat />,
      },
      {
        path: "blank",
        element: <Blank />,
      },
      {
        path: "viewuser/:id",
        element: <ViewUser />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "invoices",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <InvoiceList />,
      },
      {
        path: "detail",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: "orders",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Orders />,
      },
    ],
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },

  // Business Intelligence page
  {
    path: "businessintelligence",
    element: <DashboardLayout />,
    children: [
      {
        path: "business-chart",
        element: <BusinessChart />,
      },
      {
        path: "ir-dashboard",
        element: <IrDashboard />,
      },
    ],
  },

  //Accounts Pages
  {
    path: "accounts",
    element: <DashboardLayout />,
    children: [
      {
        path: "property-list",
        element: <PropertyList />,
      },
      {
        path: "list-corporate",
        element: <ListCorporate />,
      },
      {
        path: "floor-plans/:id",
        element: <FloorPlans />,
      },
      {
        path: "add-property",
        element: <AddProperty />,
      },
      {
        path: "add-corporate",
        element: <AddCorporate />,
      },
      {
        path: "edit-property/:id",
        element: <EditProperty />,
      },
      {
        path: "edit-corporate/:id",
        element: <EditCorporate />,
      },
    ],
  },
  //Equipment Pages
  {
    path: "equipment",
    element: <DashboardLayout />,
    children: [
      {
        path: "equipment-list",
        element: <EquipmentList />,
      },
      {
        path: "add-equipment",
        element: <AddEquipment />,
      },
      {
        path: "edit-equipment/:id",
        element: <EditEquipment />,
      },
      {
        path: "equipment-user-list",
        element: <EquipmentUserList />,
      },
      {
        path: "process-equipment",
        element: <ProcessEquipment />,
      },
      {
        path: "add-equipment-user",
        element: <AddEquipmentUser />,
      },
      {
        path: "edit-equipment-user/:id",
        element: <EditEquipmentUser />,
      },
      {
        path: "equipment-logs",
        element: <EquipmentLogs />,
      },
      {
        path: "positions-list",
        element: <PositionsList />,
      },
      {
        path: "add-position",
        element: <AddPositions />,
      },
      {
        path: "edit-position/:id",
        element: <EditPosition />,
      },
      {
        path: "department-list",
        element: <DepartmentList />,
      },
      {
        path: "add-department",
        element: <AddDepartment />,
      },
      {
        path: "equipment-detail",
        element: <EquipmentDetail />,
      },
      {
        path: "view-equipment-detail/:id",
        element: <ViewEquipmentDetail />,
      },
    ],
  },
  {
    path: "screening-survey/:ru_id",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <ScreeningSurvey />,
      },
    ],
  },
  {
    path: "survey-done",
    element: <PresentationLayout />,
    children: [
      {
        path: "",
        element: <SurveyDone />,
      },
    ],
  },
  //Tours Pages
  {
    path: "tours",
    element: <DashboardLayout />,
    children: [
      {
        path: "tour-listing",
        element: <TourListing />,
      },
      {
        path: "add-tour",
        element: <AddTour />,
      },
      {
        path: "edit-tour/:id",
        element: <EditTour />,
      },
      {
        path: "geolocate",
        element: <GeoLocate />,
      },
      {
        path: "add-tour-building",
        element: <AddTourBuilding />,
      },
      {
        path: "edit-button/:tourId/:tagId",
        element: <EditButton />,
      },
      {
        path: "checkpoints/:id",
        element: <CheckPoints />,
      },
    ],
  },
  // User Pages
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "user",
        element: <Users />,
      },
      {
        path: "adduser",
        element: <AddUser />,
      },
      {
        path: "edituser/:id",
        element: <EditUser />,
      },
    ],
  },
  // Tasks Pages
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "task-list",
        element: <ListTasks />,
      },
      {
        path: "add-task",
        element: <AddTask />,
      },
      {
        path: "edit-task/:id",
        element: <EditTask />,
      },
    ],
  },
  // Visitor Pages
  {
    path: "visitors",
    element: <DashboardLayout />,
    children: [
      {
        path: "add-visitor",
        element: <AddVisitor />,
      },
      {
        path: "edit-visitor/:id",
        element: <EditVisitor />,
      },
      {
        path: "add-company",
        element: <AddCompany />,
      },
      {
        path: "edit-company/:id",
        element: <EditCompany />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "kiosk-settings",
        element: <KioskSettings />,
      },
      {
        path: "list-visitors",
        element: <ListVisitors />,
      },
      {
        path: "process-visitor",
        element: <ProcessVisitor />,
      },
      {
        path: "process-visitor/:id",
        element: <ProcessVisitor />,
      },
      {
        path: "visit-log",
        element: <VisitLog />,
      },
      {
        path: "dashboard",
        element: <VisitorDashboard />,
      },
      {
        path: "visitor-types",
        element: <VisitorTypes />,
      },
      {
        path: "visitor-watch-list",
        element: <VisitorWatchList />,
      },
      {
        path: "view-visitor-watch/:id",
        element: <ViewVisitorWatch />,
      },
      {
        path: "edit-visitor-watch/:id",
        element: <EditVisitorWatch />,
      },
      {
        path: "add-visitor-watch",
        element: <AddVisitorWatch />,
      },
      // {
      //   path: "badge-print",
      //   element: <BadgePrint />,
      // },
    ],
  },
  {
    path: "visitors",
    element: <PresentationLayout />,
    children: [
      {
        path: "kiosk-mode",
        element: <KioskMode />,
      },
      {
        path: "check-in",
        element: <CheckIn />,
      },
      {
        path: "returning-visitor-info",
        element: <ReturningVisitorInfo />,
      },
      {
        path: "visitor-detail/:visitorId",
        element: <VisitorDetail />,
      },
      {
        path: "kiosk-sign-out",
        element: <KioskSignOut />,
      },
      {
        path: "scan-qr-code",
        element: <ScanQrCode />,
      },
      {
        path: "packages-delivery",
        element: <PackagesDelivery />,
      },
      {
        path: "kiosk-mode-check-in",
        element: <KioskModeCheckIn />,
      },
      {
        path: "kiosk-settings",
        element: <KioskSettings />,
      },
      {
        path: "confirm-returning-visitors",
        element: <ConfirmReturningVisitors />,
      },
    ],
  },
  {
    path: "visitors",
    children: [
      {
        path: "returning-visitors",
        element: <ReturningVisitors />,
      },
    ],
  },
  //Employee Accidents
  {
    path: "/employee-accidents",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EmployeeAccidents />,
      },
    ],
  },

  // Lost and Found Pages
  {
    path: "lostandfound",
    element: <DashboardLayout />,
    children: [
      {
        path: "list-lost-found",
        element: <ListLostAndFound />,
      },
      {
        path: "add-lost-found",
        element: <AddLostAndFound />,
      },
      {
        path: "add-found",
        element: <AddFound />,
      },
      {
        path: "image-search",
        element: <ImageSearch />,
      },
      {
        path: "shipments",
        element: <Shipments />,
      },
      {
        path: "shipments-admin",
        element: <ShipmentsAdmin />,
      },
      {
        path: "view-activity-logs",
        element: <ViewActivityLogs />,
      },
      {
        path: "match-lost-found",
        element: <MatchLostFound />,
      },
      {
        path: "edit-lost-found/:id",
        element: <EditLostAndFound />,
      },
      {
        path: "lost-found-image-search",
        element: <LostFoundImageSearch />,
      },
    ],
  },
  {
    path: "S4",
    element: <DashboardLayout />,
    children: [
      {
        path: "anonymous-intel-sharing",
        element: <ReportingUser />,
      },
    ],
  },
  {
    path: "S4",
    children: [
      {
        path: ":dynamicKey", // dynamic route parameter
        element: <KeyForm />,
      },
    ],
  },

  {
    path: "S4",
    element: <DashboardLayout />,
    children: [
      {
        path: "anonymous-intel-sharing",
        element: <ReportingUser />,
      },
    ],
  },
  //Live Module
  {
    path: "livemodule",
    element: <DashboardLayout />,
    children: [
      {
        path: "live-module",
        element: <LiveModule />,
      },
      {
        path: "live-cast-pics",
        element: <LiveCastPics />,
      },
    ],
  },
  // Lost and Found PDF Pages
  {
    path: "lostandfound",
    element: <NoneHeader />,
    children: [
      {
        path: "lost-found-details/:id",
        element: <LostandfoundDetails />,
      },
      {
        path: "bag-tag/:id",
        element: <BagTagPdf />,
      },
      {
        path: "transaction-pdf/:id",
        element: <TransactionDetails />,
      },
      {
        path: "process-to-shipment/:key",
        element: <ProcessToShipment />,
      },
      {
        path: "payment-done/:key",
        element: <PaymentDone />,
      },
      {
        path: "user-receipt",
        element: <UserReceipt />,
      },
    ],
  },
  {
    path: "property-inspection",
    element: <NoneHeader />,
    children: [
      {
        path: "results-pdf/:id",
        element: <PropertyInspectionResultPdf />,
      },
    ],
  },
  {
    path: "/view-employee-accidents/:id",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <ViewEmployeeAccident />,
      },
    ],
  },
  {
    path: "/edit-employee-accidents/:id",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EditEmployeeAccident />,
      },
    ],
  },
  {
    path: "/add-ear/:id",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AddEar />,
      },
    ],
  },
  {
    path: "/edit-ear/:id",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EditEar />,
      },
    ],
  },
  {
    path: "/add-ear-reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AddEarReports />,
      },
    ],
  },
  {
    path: "/edit-ear-reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <EditEarReports />,
      },
    ],
  },
  // Permissions Pages
  {
    path: "permissions",
    element: <DashboardLayout />,
    children: [
      {
        path: "edit-group-permissions",
        element: <EditGroupPermissions />,
      },
      {
        path: "user-role",
        element: <UserRole />,
      },
    ],
  },
  // Reports Pages
  {
    path: "reports",
    element: <DashboardLayout />,
    children: [
      {
        path: "send-ir-logs",
        element: <SendIrLogs />,
      },
      {
        path: "button-history",
        element: <ButtonHistory />,
      },
      {
        path: "passdown",
        element: <Passdown />,
      },
      {
        path: "add-incident-report",
        element: <AddIncidentReport />,
      },
      { path: "view-incident-report/:id", element: <ViewIncidentReport /> },
      { path: "edit-incident-report/:id", element: <EditIncidentReport /> },
      {
        path: "incidents",
        element: <Incidents />,
      },
      {
        path: "performance",
        element: <Performance />,
      },
      {
        path: "daily-activity",
        element: <DailyActivity />,
      },
      {
        path: "tour-patrol",
        element: <TourPatrol />,
      },
      {
        path: "non-tour-tasks",
        element: <NonTourTasks />,
      },
      {
        path: "tour-summary",
        element: <TourSummary />,
      },
      {
        path: "tour-report/:tourId/:start_date/:end_date",
        element: <TourReport />,
      },
      {
        path: "missed-buttons",
        element: <MissedButton />,
      },
      {
        path: "sms-reports",
        element: <SmsReports />,
      },
      {
        path: "device-reports",
        element: <DeviceReports />,
      },
    ],
  },
  // Poi pages
  {
    path: "poi",
    element: <DashboardLayout />,
    children: [
      {
        path: "poi-dashboard",
        element: <PoiDashboard />,
      },
      {
        path: "poi-list",
        element: <PoiList />,
      },
      {
        path: "add-poi",
        element: <AddPersonOfInterest />,
      },
      {
        path: "poi-encounters",
        element: <PoiEncounters />,
      },
      {
        path: "poi-image-search",
        element: <PoiImageSearch />,
      },
      {
        path: "view-poi/:id",
        element: <ViewPoi />,
      },
      {
        path: "view-poi-encounter/:id",
        element: <ViewPoiEncounter />,
      },
      {
        path: "add-poi-encounter/:id",
        element: <AddPoiEncounter />,
      },
      {
        path: "edit-poi-encounter/:id",
        element: <EditPoiEncounter />,
      },
      {
        path: "edit-poi/:id",
        element: <EditPoi />,
      },
    ],
  },
  // Packages pages
  {
    path: "packages",
    element: <DashboardLayout />,
    children: [
      {
        path: "list-packages",
        element: <ListPackages />,
      },
      {
        path: "add-package",
        element: <AddPackage />,
      },
      {
        path: "view-package/:id",
        element: <ViewPackage />,
      },
      {
        path: "edit-package/:id",
        element: <EditPackage />,
      },
      {
        path: "bulk-receiving",
        element: <BulkReceiving />,
      },
      {
        path: "package-audit",
        element: <PackageAudit />,
      },
      {
        path: "tenant-list",
        element: <TenantList />,
      },
    ],
  },
  // Settings pages
  {
    path: "settings/property-user",
    element: <DashboardLayout />,
    children: [
      {
        path: "communication",
        element: <Communication />,
      },
    ],
  },
  {
    path: "settings/corporate-user",
    element: <DashboardLayout />,
    children: [
      {
        path: "ir-numbering",
        element: <IrNumbering />,
      },
      {
        path: "list-department",
        element: <ListDepartment />,
      },
      {
        path: "ir-classifications",
        element: <IrClassifications />,
      },
      {
        path: "add-ir-classification",
        element: <AddIrClassification />,
      },
      {
        path: "edit-ir-classification/:id",
        element: <EditIrClassification />,
      },
      {
        path: "lost-and-found-privacy",
        element: <LostFoundPrivacy />,
      },
      {
        path: "trigger-words",
        element: <TriggerWords />,
      },
      {
        path: "customize-report",
        element: <CustomizeReport />,
      },

      {
        path: "ip-range",
        element: <IpRange />,
      },

      {
        path: "lost-and-found-rule",
        element: <LostAndFoundRule />,
      },
      {
        path: "lost-and-found-rule/:id",
        element: <LostAndFoundRule />,
      },

      {
        path: "venues",
        element: <Venues />,
      },
      {
        path: "modify-nav-menu",
        element: <ModifyNavMenu />,
      },
      {
        path: "e-notify-templetes",
        element: <ENotifyiTempletes />,
      },
      {
        path: "e-notify-templetes/:id",
        element: <ENotifyiTempletes />,
      },
      {
        path: "add-question",
        element: <AddQuestion />,
      },
      {
        path: "list-device",
        element: <ListDevice />,
      },
      {
        path: "login-records",
        element: <LoginRecords />,
      },
      {
        path: "module-color",
        element: <ModuleColor />,
      },
      {
        path: "notify-div",
        element: <NotifyDiv />,
      },
      {
        path: "edit-notify/:id",
        element: <EditNotify />,
      },
      {
        path: "security-events",
        element: <SecurityEvents />,
      },
      {
        path: "permission",
        element: <Permission />,
      },
      {
        path: "carrier-settings",
        element: <CarrierSettings />,
      },
      {
        path: "carrier-service-level/:carrierName",
        element: <CarrierServiceLevel />,
      },
      {
        path: "past-notify",
        element: <PastNotify />,
      },
      {
        path: "sms-report",
        element: <SmsReport />,
      },
      {
        path: "switch-dummy-data",
        element: <SwitchDummyData />,
      },
      {
        path: "user-search",
        element: <UserSearch />,
      },
      {
        path: "youtube-videos",
        element: <YoutubeVideos />,
      },
    ],
  },
  {
    path: "schedules",
    element: <DashboardLayout />,
    children: [
      {
        path: "schedule-calendar",
        element: <ScheduleCalendar />,
      },
    ],
  },
  // AED Management
  {
    path: "logs",
    element: <DashboardLayout />,
    children: [
      {
        path: "aed-management",
        element: <AedManagement />,
      },
      {
        path: "add-aed-inspection",
        element: <AddAedInspection />,
      },
      {
        path: "edit-aed-inspection/:id",
        element: <EditAedInspection />,
      },
      {
        path: "alarm-activation-log",
        element: <AlarmActivationLog />,
      },
      {
        path: "add-alarm-activation-log",
        element: <AddAlarmActivationLog />,
      },
      {
        path: "edit-alarm-activation-log/:id",
        element: <EditAlarmActivationLog />,
      },
      {
        path: "attendance-log",
        element: <AttendanceLog />,
      },
      {
        path: "emergency-procedures",
        element: <EmergencyProcedures />,
      },
      {
        path: "edit-emergency-procedures/:type/:sub_type",
        element: <EditEmergencyProcedures />,
      },
      {
        path: "covid19-survey",
        element: <Covid19Survey />,
      },
      {
        path: "view-covid19-survey/:id",
        element: <ViewCovid19Survey />,
      },
      {
        path: "key-requisition",
        element: <KeyRequisition />,
      },
      {
        path: "add-key-requisition",
        element: <AddKeyRequisition />,
      },
      {
        path: "edit-key-requisition/:id",
        element: <EditKeyRequisition />,
      },
      {
        path: "overdue-key-report",
        element: <OverdueKeyReport />,
      },
      {
        path: "add-report",
        element: <AddReport />,
      },
      {
        path: "edit-report/:id",
        element: <EditReport />,
      },
      {
        path: "view-lost-key-badge-report/:id",
        element: <ViewLostBadgeReport />,
      },
      {
        path: "locker-log",
        element: <LockerLog />,
      },
      {
        path: "lockers",
        element: <Lockers />,
      },
      {
        path: "locks",
        element: <Locks />,
      },
      {
        path: "employees",
        element: <Employees />,
      },
      {
        path: "add-employee",
        element: <AddEmployee />,
      },
      {
        path: "edit-employee/:id",
        element: <EditEmployee />,
      },
      {
        path: "vehicle-management",
        element: <VehicleManagement />,
      },
      {
        path: "vehicle-inspection",
        element: <VehicleInspection />,
      },
      {
        path: "violations/:vehicleId",
        element: <Violations />,
      },
      {
        path: "shift-report",
        element: <ShiftReport />,
      },
      {
        path: "add-shift-report",
        element: <AddShiftReport />,
      },
      {
        path: "edit-shift-report/:id",
        element: <EditShiftReport />,
      },
      {
        path: "view-shift-report/:id",
        element: <ViewShiftReport />,
      },
      {
        path: "special-visitors",
        element: <SpecialVisitors />,
      },
    ],
  },
  {
    path: "/property-inspection",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <PropertyInspection />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "confirm-password",
        element: <ConfirmPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "user-verification",
        element: <UserVerification />,
      },
    ],
  },
  {
    path: "components",
    element: <DashboardLayout />,
    children: [
      {
        path: "accordion",
        element: <Accordion />,
      },
      {
        path: "alerts",
        element: <Alerts />,
      },
      {
        path: "avatars",
        element: <Avatars />,
      },
      {
        path: "badges",
        element: <Badges />,
      },
      {
        path: "buttons",
        element: <Buttons />,
      },
      {
        path: "cards",
        element: <Cards />,
      },
      {
        path: "chips",
        element: <Chips />,
      },
      {
        path: "dialogs",
        element: <Dialogs />,
      },
      {
        path: "lists",
        element: <Lists />,
      },
      {
        path: "menus",
        element: <Menus />,
      },
      {
        path: "pagination",
        element: <Pagination />,
      },
      {
        path: "progress",
        element: <Progress />,
      },
      {
        path: "snackbars",
        element: <Snackbars />,
      },
      {
        path: "tooltips",
        element: <Tooltips />,
      },
    ],
  },
  {
    path: "forms",
    element: <DashboardLayout />,
    children: [
      {
        path: "pickers",
        element: <Pickers />,
      },
      {
        path: "selection-controls",
        element: <SelectionCtrls />,
      },
      {
        path: "selects",
        element: <Selects />,
      },
      {
        path: "text-fields",
        element: <TextFields />,
      },
      {
        path: "editors",
        element: <Editors />,
      },
      {
        path: "formik",
        element: <Formik />,
      },
    ],
  },
  {
    path: "tables",
    element: <DashboardLayout />,
    children: [
      {
        path: "simple-table",
        element: <SimpleTable />,
      },
      {
        path: "advanced-table",
        element: <AdvancedTable />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "userone",
        element: <UserOne />,
      },
      {
        path: "data-grid",
        element: <DataGrid />,
      },
    ],
  },
  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "user",
        element: <SimpleTable />,
      },
    ],
  },
  {
    path: "icons",
    element: <DashboardLayout />,
    children: [
      {
        path: "material-icons",
        element: <MaterialIcons />,
      },
      {
        path: "feather-icons",
        element: <FeatherIcons />,
      },
    ],
  },
  {
    path: "charts",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Chartjs />,
      },
    ],
  },
  {
    path: "maps",
    element: <DashboardLayout />,
    children: [
      {
        path: "google-maps",
        element: <GoogleMaps />,
      },
      {
        path: "vector-maps",
        element: <VectorMaps />,
      },
    ],
  },
  {
    path: "documentation",
    element: <DocLayout />,
    children: [
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "getting-started",
        element: <GettingStarted />,
      },
      {
        path: "routing",
        element: <Routing />,
      },
      {
        path: "auth/auth0",
        element: <Auth0 />,
      },
      {
        path: "auth/cognito",
        element: <Cognito />,
      },
      {
        path: "auth/firebase",
        element: <Firebase />,
      },
      {
        path: "auth/jwt",
        element: <JWT />,
      },
      {
        path: "guards",
        element: <Guards />,
      },
      {
        path: "environment-variables",
        element: <EnvironmentVariables />,
      },
      {
        path: "deployment",
        element: <Deployment />,
      },
      {
        path: "theming",
        element: <Theming />,
      },
      {
        path: "api-calls",
        element: <APICalls />,
      },
      {
        path: "redux",
        element: <Redux />,
      },
      {
        path: "internationalization",
        element: <Internationalization />,
      },
      {
        path: "eslint-and-prettier",
        element: <ESLintAndPrettier />,
      },
      {
        path: "migrating-to-next-js",
        element: <MigratingToNextJS />,
      },
      {
        path: "support",
        element: <Support />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DocLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
  {
    path: "/ably",
    element: <AblyReceiver />,
  },
];

export default routes;
