import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import LiveIconDilaog from "./inner-components/LiveIconDilaog";
import LivePlusDialog from "./inner-components/LivePlusDialog";
import MessageIconDialog from "./inner-components/MessageIconDialog";
import IconPopup from "./inner-components/IconPopup";
import LiveModuleDilaog from "./inner-components/LiveModuleDilaog";
import LiveModuleModal from "./inner-components/LiveModuleModal";
import { sova } from "../../config";

import {
  Grid,
  Typography,
  IconButton,
  Box,
  Chip,
  ListItem,
  Card,
  List,
  Switch,
  Button,
  Menu,
  MenuItem,
  StyledMenu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
  TextField,
  TextareaAutosize as MuiTextareaAutosize,
  CardContent,
} from "@mui/material";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { TaskActivityList, TaskUserList, AddNewTask } from "../../api";
import { spacing } from "@mui/system";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  FilterAlt as FilterAltIcon,
  Visibility as VisibilityIcon,
  Fullscreen as FullscreenIcon,
  Cached as CachedIcon,
  Groups as GroupsIcon,
  EmailRounded as EmailRoundedIcon,
  PhotoOutlined as PhotoOutlinedIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  DirectionsWalk as DirectionsWalkIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { borderTop } from "@mui/system";
const label = { inputProps: { "aria-label": "Switch demo" } };
const initialTasks = {
  notStarted: [
    {
      id: "task1",
      title: "Research Project",
      description: "Complete the research on AI.",
    },
    {
      id: "task2",
      title: "Write Report",
      description: "Draft the initial report.",
    },
  ],
  started: [],
  completed: [],
};

function LiveModule() {
  const StyledMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      maxWidth: "140px",
      width: "100%",
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 7%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        "& .MuiMenuItem-root": {
          display: "block",
          "& .MuiList-root": {
            paddingTop: "0px",
            marginTop: "15px",
            paddingBottom: "0px",
            "& .MuiListItem-root": {
              padding: "0px",
              background: "#7aa8ff0d",
              "& .MuiListItemButton-root": {
                padding: "3px 5px",
              },
            },
          },
        },

        "& .Mui-focusVisible": {
          backgroundColor: "transparent",
        },
      },
    },
  }));
  const ImageWrapper = styled.div``;
  const Image = styled.img`
    width: 100%;
  `;
  const TextareaAutosize = styled(MuiTextareaAutosize)`
    ${spacing};
    border: 1px solid
      ${(props) =>
        props.theme.palette.mode === "dark" ? "#c4c4c4" : "#c4c4c4"};
    border: 1px solid #c4c4c4;
    border-radius: 5px;
    height: 120px !important;
    background: transparent;
    padding: 20px 12px 15px;
    font-size: 13px;
    opacity: 0.8;
    font-weight: 200;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
    letter-spacing: normal;
    width: 100%;
  `;
  const [addTask, setAddTask] = React.useState(false);
  const [selectedActivity, setSelectedActivity] = useState("");
  const [selectedOfficer, setSelectedOfficer] = useState("");
  const handleTaskOpen = () => {
    setAddTask(true);
  };

  const handleTaskClose = () => {
    setAddTask(false);
  };
  useEffect(() => {
    if (addTask) {
      TaskActivityList()
        .then((response) => {
          console.log("TaskActivityList response:", response.data); // Debug log
          setActivityList(response.data || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching TaskActivityList:", error);
          setActivityList([]); // Set as empty array if there's an error
        });

      TaskUserList()
        .then((response) => {
          console.log("TaskUserList response:", response.data); // Debug log
          setUserList(response.data || []); // Ensure it's an array
        })
        .catch((error) => {
          console.error("Error fetching TaskUserList:", error);
          setUserList([]); // Set as empty array if there's an error
        });
    }
  }, [addTask]);

  const [iconPopup, setIconPopup] = React.useState(false);
  const iconPopupOpen = () => {
    setIconPopup(true);
  };
  const iconPopupClose = () => {
    setIconPopup(false);
  };
  const [emailDialog, setEmailDialog] = React.useState(false);
  const emailDialogOpen = () => {
    setEmailDialog(true);
  };
  const emailDialogClose = () => {
    setEmailDialog(false);
  };

  const [livePlusDilaog, setLivePlusDilaog] = React.useState(false);
  const livePlusDilaogOpen = () => {
    setLivePlusDilaog(true);
  };
  const livePlusDilaogClose = () => {
    setLivePlusDilaog(false);
  };
  const [liveIconDilaog, setLiveIconDilaog] = React.useState(false);
  const liveIconDilaogOpen = () => {
    setLiveIconDilaog(true);
  };
  const liveIconDilaogClose = () => {
    setLiveIconDilaog(false);
  };

  const [liveModuleDilaog, setLiveModuleDilaog] = React.useState(false);
  const liveModuleDilaogOpen = () => {
    setLiveModuleDilaog(true);
  };
  const liveModuleDilaogClose = () => {
    setLiveModuleDilaog(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const [liveModal, setLiveModal] = React.useState(false);
  const liveModalOpen = () => {
    setLiveModal(true);
  };
  const liveModalClose = () => {
    setLiveModal(false);
  };
  const [location, setLocation] = useState("");
  const [informant, setInformant] = useState("");
  const [comment, setComment] = useState("");

  // Google map
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: sova.GoogleApiKey,
  });
  const [mapCenter, setMapCenter] = useState({
    lat: 37.773972,
    lng: -122.431297,
  });
  const [isDivVisible, setIsDivVisible] = useState(false);

  const handleButtonClick = () => {
    setIsDivVisible(true); // Show the div and hide the button
  };

  const handleCloseClick = () => {
    setIsDivVisible(false); // Hide the div and show the button
  };
  // Drag and Drop

  const [tasks, setTasks] = useState(initialTasks);
  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    if (source.droppableId === destination.droppableId) {
      const updatedTasks = Array.from(tasks[source.droppableId]);
      const [movedTask] = updatedTasks.splice(source.index, 1);
      updatedTasks.splice(destination.index, 0, movedTask);

      setTasks((prevState) => ({
        ...prevState,
        [source.droppableId]: updatedTasks,
      }));
    } else {
      const sourceTasks = Array.from(tasks[source.droppableId]);
      const destinationTasks = Array.from(tasks[destination.droppableId]);
      const [movedTask] = sourceTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, movedTask);

      setTasks((prevState) => ({
        ...prevState,
        [source.droppableId]: sourceTasks,
        [destination.droppableId]: destinationTasks,
      }));
    }
  };

  // Add New Task
  const [activityList, setActivityList] = useState([]); // Initialize as empty array
  const [userList, setUserList] = useState([]);
  const handleActivityChange = (event) => {
    setSelectedActivity(event.target.value);
  };

  const handleUserChange = (event) => {
    setSelectedOfficer(event.target.value);
  };
  const handleSave = () => {
    if (
      !selectedActivity ||
      !selectedOfficer ||
      !location ||
      !informant ||
      !comment
    ) {
      alert("All fields are required.");
      return;
    }

    const payload = {
      Nontourdata: {
        TaskID: selectedActivity,
        UserID: selectedOfficer,
        location,
        informant,
        Comment: comment,
        start_time: "0",
        ScanTime: "",
      },
    };

    AddNewTask(payload)
      .then((response) => {
        console.log("Task added successfully:", response.data);
        handleTaskClose();
      })
      .catch((error) => {
        console.error("Error adding new task:", error);
      });
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={4} md={4}>
          <Box
            sx={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button variant="outlined" color="error">
              1
            </Button>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginBottom: "0px",
              }}
            >
              Last Update: 00:00:00
            </Typography>
            <Box sx={{ display: "flex" }}>
              <IconButton onClick={liveIconDilaogOpen}>
                <FilterAltIcon />
              </IconButton>
              <Button
                aria-label="Settings"
                aria-owns={anchorEl ? "icon-menu" : undefined}
                aria-haspopup="true"
                onClick={handleActionClick}
                style={{ color: "#1374C5" }}
                color="success"
                variant="outlined"
              >
                2
              </Button>
              <StyledMenu
                id="icon-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleActionClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Inactive Users
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Web Developer
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography variant="body2" gutterBottom>
                    Sandeep Kundu
                  </Typography>
                </MenuItem>
              </StyledMenu>
            </Box>
          </Box>
          <Card
            variant="outlined"
            sx={{
              marginTop: "10px",
            }}
          >
            <List
              sx={{
                height: "566px",
                overflowY: "auto",
                scrollbarWidth: "none",
              }}
            >
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton onClick={liveModuleDilaogOpen}>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  onClick={liveModalOpen}
                >
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                  borderBottom: "1px solid rgb(199 199 199)",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: "block",
                }}
              >
                <Typography variant="body2" gutterBottom>
                  Web Developer Status: inactive
                  <IconButton>
                    <DirectionsWalkIcon fontSize="small" />
                  </IconButton>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Marigold Hotel
                </Typography>
                <Typography variant="body2" gutterBottom>
                  01/06/2023 17:32:32(America/Tijuana)
                </Typography>
              </ListItem>
            </List>
          </Card>
        </Grid>

        <Grid item xs={8} md={8}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Switch {...label} defaultChecked />

            <IconButton sx={{ marginLeft: "auto" }}>
              <FullscreenIcon />
            </IconButton>
            <IconButton>
              <VisibilityIcon />
            </IconButton>
            <IconButton>
              <CachedIcon />
            </IconButton>
            <IconButton>
              <PhotoOutlinedIcon />
            </IconButton>
            <IconButton onClick={emailDialogOpen}>
              <EmailRoundedIcon />
            </IconButton>
            <IconButton>
              <GroupsIcon />
            </IconButton>
            <IconButton onClick={livePlusDilaogOpen}>
              <AddBoxOutlinedIcon color="error" />
            </IconButton>
          </Box>

          <Box
            sx={{
              paddingTop: "10px",
              height: "576px",
              position: "relative",
            }}
          >
            {!isDivVisible && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "-19px",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ position: "relative", height: "19px" }}
                  onClick={handleButtonClick}
                >
                  <MenuIcon color="white" />
                </Button>
              </div>
            )}

            {isDivVisible && (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Box
                  sx={{
                    position: "absolute",
                    width: "100%",
                    padding: "7px",
                    backgroundColor: "transparent",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "10px",
                    zIndex: "1",
                  }}
                >
                  {/* Not Started Column */}
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        padding: "10px",
                        background: "aliceblue",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      Not Started ({tasks.notStarted.length})
                    </Typography>
                    <Droppable droppableId="notStarted">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            height: "400px",
                            background: "rgba(242, 242, 242, 0.8)",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {tasks.notStarted.map((task, index) => (
                            <Draggable
                              key={task.id}
                              draggableId={task.id}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    marginTop: "10px",
                                  }}
                                >
                                  <CardContent sx={{ padding: "0px" }}>
                                    <Typography variant="h6">
                                      {task.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {task.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "0",
                        left: "2px",
                        cursor: "pointer",
                      }}
                    >
                      <AddIcon
                        color="primary"
                        fontSize="large"
                        onClick={handleTaskOpen}
                        sx={{ cursor: "pointer" }}
                      />
                    </div>
                  </Box>

                  {/* Started Column */}
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{
                        padding: "10px",
                        background: "aliceblue",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      Started ({tasks.started.length})
                    </Typography>
                    <Droppable droppableId="started">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            height: "400px",
                            background: "rgba(242, 242, 242, 0.8)",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {tasks.started.map((task, index) => (
                            <Draggable
                              key={task.id}
                              draggableId={task.id}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    marginTop: "10px",
                                  }}
                                >
                                  <CardContent sx={{ padding: "0px" }}>
                                    <Typography variant="h6">
                                      {task.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {task.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>

                  {/* Completed Column */}
                  <Box>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        background: "aliceblue",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <Typography variant="h6">
                        Completed ({tasks.completed.length})
                      </Typography>
                      <IconButton
                        onClick={handleCloseClick}
                        sx={{ padding: "0px" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <Droppable droppableId="completed">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            height: "400px",
                            background: "rgba(242, 242, 242, 0.8)",
                            paddingRight: "8px",
                            paddingLeft: "8px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {tasks.completed.map((task, index) => (
                            <Draggable
                              key={task.id}
                              draggableId={task.id}
                              index={index}
                            >
                              {(provided) => (
                                <Card
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    marginBottom: "10px",
                                    padding: "10px",
                                    backgroundColor: "#fff",
                                    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                                    marginTop: "10px",
                                  }}
                                >
                                  <CardContent sx={{ padding: "0px" }}>
                                    <Typography variant="h6">
                                      {task.title}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {task.description}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                </Box>
              </DragDropContext>
            )}

            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "280px",
                zIndex: "1",
                background: "white",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
                cursor: "pointer",
              }}
            >
              <ArrowForwardIosIcon
                color="primary"
                fontSize="large"
                sx={{ cursor: "pointer" }}
                onClick={iconPopupOpen}
              />
            </div>

            {loadError ? (
              "Error loading maps"
            ) : !isLoaded ? (
              "Loading maps..."
            ) : (
              <GoogleMap
                id="map"
                mapContainerStyle={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
                zoom={16}
                center={mapCenter}
                options={{ mapTypeId: "satellite" }}
              >
                {locations.map((location, index) => (
                  <Marker
                    key={index}
                    position={location}
                    icon={{
                      url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                      scaledSize: new window.google.maps.Size(30, 30),
                      origin: new window.google.maps.Point(0, 0),
                      anchor: new window.google.maps.Point(15, 15),
                    }}
                    title={location.title}
                    onClick={() => {
                      setSelectedLocation(location);
                    }}
                  />
                ))}
                {selectedLocation && (
                  <InfoWindow
                    position={selectedLocation}
                    onCloseClick={() => {
                      setSelectedLocation(null);
                    }}
                  >
                    <div
                      style={{
                        minWidth: "320px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ paddingRight: "12px" }}>
                        <p>Property: {selectedLocation.property}</p>
                        <p>Alias: {selectedLocation.alias}</p>
                        <p>Threat Level: {selectedLocation.threat_level}</p>
                        <p>Encounter Time: {selectedLocation.encounter_time}</p>
                        <p>Group: {selectedLocation.group}</p>
                      </div>
                    </div>
                  </InfoWindow>
                )}
              </GoogleMap>
            )}
          </Box>
        </Grid>
      </Grid>
      {liveModal && (
        <LiveModuleModal
          liveModal={liveModal}
          setLiveModal={setLiveModal}
          liveModalOpen={liveModalOpen}
          liveModalClose={liveModalClose}
        />
      )}

      {liveModuleDilaog && (
        <LiveModuleDilaog
          liveModuleDilaog={liveModuleDilaog}
          setLiveModuleDilaog={setLiveModuleDilaog}
          liveModuleDilaogOpen={liveModuleDilaogOpen}
          liveModuleDilaogClose={liveModuleDilaogClose}
        />
      )}
      {liveIconDilaog && (
        <LiveIconDilaog
          liveIconDilaog={liveIconDilaog}
          setLiveIconDilaog={setLiveIconDilaog}
          liveIconDilaogOpen={liveIconDilaogOpen}
          liveIconDilaogClose={liveIconDilaogClose}
        />
      )}
      {livePlusDilaog && (
        <LivePlusDialog
          livePlusDilaog={livePlusDilaog}
          setLivePlusDilaog={setLivePlusDilaog}
          livePlusDilaogOpen={livePlusDilaogOpen}
          livePlusDilaogClose={livePlusDilaogClose}
        />
      )}
      {emailDialog && (
        <MessageIconDialog
          emailDialog={emailDialog}
          setEmailDialog={setEmailDialog}
          emailDialogOpen={emailDialogOpen}
          emailDialogClose={emailDialogClose}
        />
      )}
      {iconPopup && (
        <IconPopup
          iconPopup={iconPopup}
          setIconPopup={setIconPopup}
          iconPopupOpen={iconPopupOpen}
          iconPopupClose={iconPopupClose}
        />
      )}

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={addTask}
        onClose={handleTaskClose}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px !important",
          }}
        >
          <Typography variant="h5">Add Activity</Typography>
          <IconButton onClick={handleTaskClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6} mt={2}>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Activity</InputLabel>
                <Select
                  labelId="activity-select-label"
                  value={selectedActivity}
                  onChange={handleActivityChange}
                >
                  {Array.isArray(activityList) && activityList.length > 0 ? (
                    activityList.map((activity) => (
                      <MenuItem key={activity.id} value={activity.id}>
                        {activity.text} {/* Displaying only the text */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Officer</InputLabel>
                <Select
                  labelId="officer-select-label"
                  value={selectedOfficer}
                  onChange={handleUserChange}
                >
                  {Array.isArray(userList) && userList.length > 0 ? (
                    userList.map((officer) => (
                      <MenuItem key={officer.id} value={officer.id}>
                        {officer.text} {/* Displaying only the text */}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Loading</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                label="Location"
                fullWidth
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                label="Informant"
                fullWidth
                value={informant}
                onChange={(e) => setInformant(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextareaAutosize
                placeholder="Comment about the task"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleTaskClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LiveModule;
