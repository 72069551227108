import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import {
  CategoryList,
  StorageLocationList,
  UpdateOneColumn,
  UpdateDispose,
  CloneLostFound,
} from "../../../api";
import CustomSelect from "../../../components/form-components/CustomSelect";
import CustomTextField from "../../../components/form-components/CustomTextField";
import CustomAlert from "../../../components/form-components/CustomAlert";
import { handleApiResonseErrors } from "../../../helper/helper";
import MergeGroup from "../../../components/modals/lostandfound/MergeGroup";
import MergeDuplicates from "../../../components/modals/lostandfound/MergeDuplicates";
import ShipmentRequest from "../../../components/modals/lostandfound/ShipmentRequest";
import FindRecord from "../../../components/modals/lostandfound/FindRecord";
import StyledColumnOptions from "../../../helper/StyledColumnOptions";
import { useSelector, useDispatch } from "react-redux";
import { setLostFoundListing } from "../../../redux/slices/lostfound/lostfoundTableCalSlice";
import LostFoundListingCol from "../../table-columns-json/lostfound/LostFoundListing";

import {
  Checkbox,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper as MuiPaper,
  ListItemText as MuiListItemText,
  Toolbar,
  Typography,
  InputLabel as MuiInputLabel,
  FormControl as MuiFormControl,
  CardHeader,
  CardContent,
  Card,
  Box,
  Button,
  ListItemIcon,
  Menu,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import { Filter } from "react-feather";
import {
  FilterList as FilterListIcon,
  AddBox as AddBoxIcon,
  Visibility as VisibilityIcon,
  Settings as SettingsIcon,
  SettingsOverscan as SettingsOverscanIcon,
  Compare as CompareIcon,
  ArrowRight as ArrowRightIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Cancel as CancelIcon,
  SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { DatePicker } from "@mui/lab";

const Paper = styled(MuiPaper)(spacing);
const FormControlSpacing = styled(MuiFormControl)(spacing);
const ListItemText = styled(MuiListItemText)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const InputLabel = styled(MuiInputLabel)`
  ${spacing};

  background-color: ${(props) =>
    props.theme.palette.mode === "dark" ? "#233044" : "#fff"};
`;
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const useStyles = makeStyles((theme) => ({
  alphabets: {
    marginTop: "15px",
    "& .MuiList-root": {
      display: "flex",
      overflowX: "auto",
      background: "#7aa8ff0d",
      padding: "0px",
      "& .MuiListItemButton-root": {
        paddingLeft: "13px",
        paddingRight: "13px",
        textAlign: "center",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  shipmentRequest: {
    "& .MuiFormControl-root": {
      minWidth: "auto",
      width: "100%",
    },
  },
  filterDataByTabs: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "15px",
    "& .MuiToggleButtonGroup-root": {
      justifyContent: "space-between",
      width: "100%",
      overflowX: "auto",
      "& .MuiButtonBase-root": {
        borderRadius: "0",
        width: "100%",
        whiteSpace: "nowrap",
      },
    },
    "& .MuiList-root::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      backgroundColor: "#bed2f561",
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      backgroundColor: "#92a6c96e",
    },
  },
  btnDropdown: {
    maxWidth: "65px",
    width: "100%",
  },
  statusButton: {
    width: "20px",
    height: "20px",
    display: "block",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: "6px",
    color: "#fff",
    fontSize: "12px",
    fontWeight: "bolder",
  },
  cardHeader: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  filterByRadioControls: {
    position: "absolute",
    top: "100%",
    right: "0px",
    boxShadow: "0px 3px 5px 3px #00000014 !important",
    minWidth: "320px",
    zIndex: "1",
    padding: "15px",
  },
  itemInSingleRow: {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  createMergedGroup: {
    border: "1px solid",
    borderColor:
      theme.palette.mode === "light" ? "#c4c4c4" : theme.palette.grey[300],
    padding: 0,
    marginTop: "15px",
  },
  CustomActionsButton: {
    borderColor:
      theme.palette.mode === "light"
        ? "rgb(223 223 223)"
        : theme.palette.grey[300],
    color: theme.palette.mode === "light" ? "#7f8797" : theme.palette.grey[300],
    padding: "13px 20px",
  },
}));
const StyledRadio = styled((props) => <RadioGroup {...props} />)(
  ({ theme }) => ({
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    marginTop: "5px",
  })
);
const Image = styled.img`
  max-width: initial;
  margin: 20px auto 0;
  width: 100%;
  border: 1px solid grey;
  border-radius: 8px;
`;
const StyledFilterMenu = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
      },
    },
  })
);
const ActionsMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiListItemIcon-root": {
        justifyContent: "flex-end",
        marginRight: "-5px",
      },
    },
  },
}));
const StyledFilter = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    maxWidth: "250px",
    width: "100%",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: "0px 0",
      "& .MuiPaper-root": {
        margin: "0",
        padding: "0px",
        "& .MuiCardHeader-root": {
          backgroundColor: "rgba(55,111,208,0.08)",
          "& .MuiTypography-root": {
            marginBottom: "0px",
          },
        },
        "& .MuiCardHeader-content": {
          display: "none",
        },
        "& .MuiCardHeader-action": {
          width: "100%",
        },
        "& .MuiCardContent-root": {
          padding: "0px",
          maxHeight: "155px",
          overflowY: "auto",
          "& .MuiPaper-root": {
            boxShadow: "none",
            "& .MuiAccordionSummary-content": {
              margin: "0px",
              "& .MuiCheckbox-root": {
                padding: "3px",
                marginRight: "5px",
              },
            },
            "& .MuiAccordionSummary-root": {
              padding: "5px 0",
              borderTop: "0px",
              borderBottom: "1px solid #0f0f0f1a",
              minHeight: "auto",
              "& .MuiFormControlLabel-root": {
                width: "100%",
                padding: "0 15px 0 18px",
              },
            },
          },
        },
        "& .MuiAccordionDetails-root": {
          padding: "12px 15px 15px",
          "& .MuiFormControl-root": {
            minWidth: "auto",
            width: "100%",
            "& .MuiInputBase-input": {
              padding: "10px 14px",
            },
          },
        },
      },
    },
  },
}));
const StyledColumnDropdown = styled((props) => <Menu {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      marginTop: "0px",
      boxShadow:
        "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
      "& .MuiMenu-list": {
        padding: "0px 0",
        maxWidth: "500px",
        "& .MuiMenuItem-root": {
          padding: "0",
          display: "block",
          "& .MuiBox-root": {
            marginTop: "15px",
            marginBottom: "20px",
            paddingBottom: "20px",
            borderBottom: "1px solid #f1f1f1",
            maxHeight: "200px",
            overflowY: "auto",
            "& .MuiFormGroup-root": {
              flexDirection: "row",
              "& .MuiFormControlLabel-root": {
                maxWidth: "140px",
                width: "100%",
              },
            },
          },
          "& .MuiToolbar-root": {
            minHeight: "auto",
            padding: "0px",
          },
        },
      },
    },
  })
);
const SubMenu = styled((props) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    marginTop: "0px",
    boxShadow:
      "rgb(255 255 255) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px -1px, rgb(0 0 0 / 35%) 0px 2px 9px 0px, rgb(0 0 0 / 0%) 0px 0 6px -1px",
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& .MuiListItemIcon-root": {
      justifyContent: "flex-end",
    },
  },
}));

function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...other}
    />
  );
}
Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const EnhancedTableToolbar = ({
  numSelected,
  filterRecord,
  dataToFilter,
  selectedRecords,
  totalRecordinDb,
  updateAlphabetsFilter,
  setAlphabetsButton,
  setShowSubItemModal,
  setRecordId,
  selectedRecordId,
  selectedRecordData,
  reloadList,
  handelListLoader,
  resetSelected,
  selectedIdDS,
}) => {
  const [cloneLostFound, setCloneLostFound] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloneOpen = () => {
    setCloneLostFound(true);
    handleActionsClose(false);
  };
  const CloseLostFoundClone = () => {
    setCloneLostFound(false);
  };
  const handleClone = async () => {
    if (!selectedRecordId) {
      setAlertMessage("No record selected to clone.");
      setAlertType("error");
      setShowAlert(true);
      return;
    }

    setLoading(true);
    try {
      const response = await CloneLostFound(`${selectedRecordId}`); // Send record ID in the URL
      if (response.statusCode === 200) {
        setAlertMessage("Record cloned successfully!");
        setAlertType("success");
        window.location.reload();
      } else {
        setAlertMessage("Failed to clone the record.");
        setAlertType("error");
      }
    } catch (error) {
      setAlertMessage("An error occurred while cloning the record.");
      setAlertType("error");
    } finally {
      setLoading(false);
      setCloneLostFound(false); // Close the dialog after the operation
      setShowAlert(true); // Show the alert with the result
    }
  };

  // Prievew Image
  const [prievewImage, setPrievewImage] = useState(false);

  const prievewImageOpen = () => {
    setPrievewImage(true);
  };
  const prievewImageClose = () => {
    setPrievewImage(false);
  };

  const [filterFormLoading, setFilterFormLoading] = useState(true); //State for loader

  const navigate = useNavigate();
  // Redux
  const lostFoundListing = useSelector(
    (state) => state.lostfoundTableCal.lostFoundListing
  );
  const dispatch = useDispatch();
  const defaultTableCalRedux = useSelector(
    (state) => state.defaultLostfoundTableCal.lostFoundListing
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // handle Image click (Image in Actions)
  // const handleImageClick = (image) => {
  //   if (image == null) {
  //     alert("Image not found for this record");
  //   }
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Columns Dropdown
  const [anchorColumn, setAnchorColumn] = useState(null);
  const handleClickColumn = (event) => {
    setAnchorColumn(event.currentTarget);
  };
  const handleCloseColumn = () => {
    setAnchorColumn(null);
  };
  // Submenu Dropdown
  const [dropdowncategory, setDropCategory] = useState(null);
  const handleClickCategory = (event) => {
    setDropCategory(event.currentTarget);
  };
  const handleCloseCategory = () => {
    setDropCategory(null);
  };
  // Storage Location Dropdown
  const [slocation, setStorageLocDropdown] = useState(null);
  const handleClickSLocation = (event) => {
    setStorageLocDropdown(event.currentTarget);
  };
  const handleCloseSLocation = () => {
    setStorageLocDropdown(null);
  };
  // Follow Up Dropdown
  const [followupdrop, setFollowupDropdown] = useState(null);
  const handleClickFollowup = (event) => {
    setFollowupDropdown(event.currentTarget);
  };
  const handleCloseFollowup = () => {
    setFollowupDropdown(null);
  };
  // Disposed Dropdown
  const [disposeddrop, setDisposedDropdown] = useState(null);
  const handleClickDisposed = (event) => {
    setDisposedDropdown(event.currentTarget);
  };
  const handleCloseDisposed = () => {
    setDisposedDropdown(null);
  };
  //Scan Visitor ID modal
  const [open, lostFoundModal] = useState(false);
  const lostFoundModalOpen = () => {
    lostFoundModal(true);
  };
  const lostFoundModalClose = () => {
    lostFoundModal(false);
  };

  const classes = useStyles();

  const [togglefilter, setToggleFilter] = useState(null);
  const handleToggleClick = (event) => {
    setToggleFilter(event.currentTarget);
  };
  const handleToggleClose = () => {
    setToggleFilter(null);
  };
  // Actions menu
  const [actionsmenu, setActionsMenu] = useState(null);
  const handleActionsMenu = (event) => {
    setActionsMenu(event.currentTarget);
    let recordId = selectedRecords.length > 0 ? selectedRecords[0] : "";
    setRecordId(recordId);
  };
  const handleActionsClose = () => {
    setActionsMenu(null);
  };

  const [itemcatglist, setItemCatgList] = useState([]);
  const [storageLocation, setStorageLocation] = useState([]);

  const validationSchema = Yup.object().shape({
    found_date_in_the_last_type: Yup.string().when(
      ["found_date_in_the_last_value", "found_date_filter_type"],
      (foundDateInTheLastValue, foundDateFilterType, schema) => {
        return foundDateInTheLastValue &&
          foundDateFilterType === "is_in_the_last"
          ? schema.required("Required.")
          : schema;
      }
    ),
    lost_date_in_the_last_type: Yup.string().when(
      ["lost_date_in_the_last_value", "lost_date_filter_type"],
      (lostDateInTheLastValue, lostDateFilterType, schema) => {
        return lostDateInTheLastValue && lostDateFilterType === "is_in_the_last"
          ? schema.required("Required.")
          : schema;
      }
    ),
  });

  const date_filter_type = [
    {
      key: "is in the last",
      value: "is_in_the_last",
    },
    {
      key: "is equal to",
      value: "is_equal_to",
    },
    {
      key: "is between",
      value: "is_between",
    },

    {
      key: "is after",
      value: "is_after",
    },
    {
      key: "is on or after",
      value: "is_on_or_after",
    },
    {
      key: "is before",
      value: "is_before",
    },
    {
      key: "is before or on",
      value: "is_before_or_on",
    },
  ];
  let initialValues = {
    id: dataToFilter ? dataToFilter.id ?? "" : "",
    description: dataToFilter ? dataToFilter.description ?? "" : "",
    category: dataToFilter ? dataToFilter.category ?? "" : "",
    lost_last_name: dataToFilter ? dataToFilter.lost_last_name ?? "" : "",

    // for lost data
    found_date_filter_type: dataToFilter
      ? dataToFilter.found_date_filter_type ?? ""
      : "",
    found_date: dataToFilter ? dataToFilter.found_date ?? "" : "",
    found_date_in_the_last_value: dataToFilter
      ? dataToFilter.found_date_in_the_last_value ?? ""
      : "",
    found_date_in_the_last_type: dataToFilter
      ? dataToFilter.found_date_in_the_last_type ?? ""
      : "",
    found_from_date: dataToFilter ? dataToFilter.found_from_date ?? "" : "",
    found_to_date: dataToFilter ? dataToFilter.found_to_date ?? "" : "",

    lost_date_filter_type: dataToFilter
      ? dataToFilter.lost_date_filter_type ?? ""
      : "",
    lost_date: dataToFilter ? dataToFilter.lost_date ?? "" : "",
    lost_date_in_the_last_value: dataToFilter
      ? dataToFilter.lost_date_in_the_last_value ?? ""
      : "",
    lost_date_in_the_last_type: dataToFilter
      ? dataToFilter.lost_date_in_the_last_type ?? ""
      : "",
    lost_from_date: dataToFilter ? dataToFilter.lost_from_date ?? "" : "",
    lost_to_date: dataToFilter ? dataToFilter.lost_to_date ?? "" : "",

    found_location: dataToFilter ? dataToFilter.found_location ?? "" : "",
    storage_location: dataToFilter ? dataToFilter.storage_location ?? "" : "",

    checkbox_desc: dataToFilter ? dataToFilter.checkbox_desc ?? false : false,
    checkbox_category: dataToFilter
      ? dataToFilter.checkbox_category ?? false
      : false,
    checkbox_found_date: dataToFilter
      ? dataToFilter.checkbox_found_date ?? false
      : false,
    checkbox_lost_date: dataToFilter
      ? dataToFilter.checkbox_lost_date ?? false
      : false,
    checkbox_lost_last_name: dataToFilter
      ? dataToFilter.checkbox_lost_last_name ?? false
      : false,
    checkbox_found_location: dataToFilter
      ? dataToFilter.checkbox_found_location ?? false
      : false,
    checkbox_store_location: dataToFilter
      ? dataToFilter.checkbox_store_location ?? false
      : false,
    checkbox_control_id: dataToFilter
      ? dataToFilter.checkbox_control_id ?? false
      : false,
  };

  useEffect(() => {
    getStorageLocationList();
    getCategoryList();
  }, []);

  const getStorageLocationList = () => {
    StorageLocationList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setStorageLocation(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategoryList = () => {
    CategoryList({})
      .then((res) => {
        if (res.statusCode == 200) {
          setItemCatgList(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let formData = {
        id: values.id,
        description: values.description,
        category: values.category,
        lost_last_name: values.lost_last_name,
        found_date_filter_type: values.found_date_filter_type,
        found_date:
          values.found_date_filter_type === "is_in_the_last"
            ? ""
            : values.found_date_filter_type === "is_between"
            ? ""
            : values.found_date,
        found_date_in_the_last_value: values.found_date_in_the_last_value,
        found_date_in_the_last_type: values.found_date_in_the_last_type,
        found_from_date: values.found_from_date,
        found_to_date: values.found_to_date,

        lost_date_filter_type: values.lost_date_filter_type,
        lost_date:
          values.lost_date_filter_type === "is_in_the_last"
            ? ""
            : values.lost_date_filter_type === "is_between"
            ? ""
            : values.lost_date,
        lost_from_date: values.lost_from_date,
        lost_to_date: values.lost_to_date,
        lost_date_in_the_last_value: values.lost_date_in_the_last_value,
        lost_date_in_the_last_type: values.lost_date_in_the_last_type,
        found_location: values.found_location,
        storage_location: values.storage_location,

        checkbox_desc: values.checkbox_desc,
        checkbox_category: values.checkbox_category,
        checkbox_found_date: values.checkbox_found_date,
        checkbox_lost_date: values.checkbox_lost_date,
        checkbox_lost_last_name: values.checkbox_lost_last_name,
        checkbox_found_location: values.checkbox_found_location,
        checkbox_store_location: values.checkbox_store_location,
        checkbox_control_id: values.checkbox_control_id,
        searchByAlphabet: false,
      };
      setAlphabetsButton("");
      filterRecord(formData);
    } catch (error) {}
  };

  // Create merged group modal
  const [createmerge, setCreateMergedOpen] = useState(false);
  const createMergedGroupOpen = () => {
    setCreateMergedOpen(true);
  };
  // Merge Duplicates modal
  const [mergeduplicates, setMergeDuplicates] = useState(false);
  const mergeDuplicatesOpen = () => {
    setMergeDuplicates(true);
  };
  // Send shipment request
  const [shipmentrequest, setShipmentRequest] = useState(false);
  const shipmentRequestOpen = () => {
    setShipmentRequest(true);
  };
  const subItemsOpen = () => {
    setShowSubItemModal(true);
  };
  const redirectToEdit = () => {
    navigate({ pathname: "/lostandfound/edit-lost-found/" + selectedRecordId });
  };
  //Scan Barcode Modal
  const [scanbarcode, setScanBarcode] = useState(false);

  const updateRecordsvalue = (column, value) => {
    if (selectedRecords.length > 0) {
      let payload = {
        ids: selectedRecords,
        column: column,
        value: value,
      };
      handleCloseCategory();
      handleCloseSLocation();
      handleCloseFollowup();
      handleCloseDisposed();
      handleActionsClose();
      handelListLoader(true);
      UpdateOneColumn(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            resetSelected();
            setAlertMessage(
              "A total of " +
                selectedRecords.length +
                " record(s) has been updated successfully"
            );
            setAlertType("success");
            setShowAlert(true);
            reloadList();
          } else {
            setAlertMessage(res.message);
            setAlertType("success");
            setShowAlert(true);
            handelListLoader(false);
          }
        })
        .catch((err) => {
          handelListLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setAlertMessage(error);
            setAlertType("error");
            setShowAlert(true);
          }
        });
    } else {
      handelListLoader(false);
      setAlertMessage("You haven't selected any item!");
      setAlertType("error");
      setShowAlert(true);
    }
  };

  const disposeUpdate = (value) => {
    if (selectedRecords.length > 0) {
      let payload = {
        ids: selectedRecords,
        disposed_type: value,
      };
      handleCloseCategory();
      handleCloseSLocation();
      handleCloseFollowup();
      handleCloseDisposed();
      handleActionsClose();
      handelListLoader(true);
      UpdateDispose(payload)
        .then((res) => {
          if (res.statusCode == 200) {
            resetSelected();
            setAlertMessage(res.message);
            setAlertType("success");
            setShowAlert(true);
            reloadList();
          } else {
            setAlertMessage(res.message);
            setAlertType("error");
            setShowAlert(true);
            handelListLoader(false);
          }
        })
        .catch((err) => {
          handelListLoader(false);
          let errors = err.data.errors;
          let error = handleApiResonseErrors(errors);
          if (error) {
            setAlertMessage(error);
            setAlertType("error");
            setShowAlert(true);
          }
        });
    } else {
      handelListLoader(false);
      setAlertMessage("You haven't selected any item!");
      setAlertType("error");
      setShowAlert(true);
    }
  };

  const showAlertTost = (msg, type) => {
    setAlertMessage(msg);
    setAlertType(type);
    setShowAlert(true);
  };

  return (
    <React.Fragment>
      <Toolbar>
        <div>
          <Paper mt={3}>
            <Button
              variant="outlined"
              aria-owns={actionsmenu ? "action-menu" : undefined}
              aria-haspopup="true"
              onClick={handleActionsMenu}
              color="secondary"
              className={classes.CustomActionsButton}
            >
              Actions
              <ArrowDropDownIcon />
            </Button>
            <ActionsMenu
              id="action-menu"
              anchorEl={actionsmenu}
              open={Boolean(actionsmenu)}
              onClose={handleActionsClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {numSelected === 1 && (
                <MenuItem onClick={redirectToEdit}>
                  <ListItemText size="large" primary="Edit" />
                </MenuItem>
              )}
              {numSelected === 1 && (
                <MenuItem onClick={prievewImageOpen}>
                  <ListItemText size="large" primary="Image" />
                </MenuItem>
              )}
              {numSelected === 1 && (
                <StyledLink
                  target="_blank"
                  to={"/lostandfound/bag-tag/" + selectedRecordId}
                >
                  <MenuItem>
                    <ListItemText size="large" primary="Tag" />
                  </MenuItem>
                </StyledLink>
              )}
              {numSelected === 1 && (
                <StyledLink
                  target="_blank"
                  to={"/lostandfound/lost-found-details/" + selectedRecordId}
                >
                  <MenuItem>
                    <ListItemText size="large" primary="Detail" />
                  </MenuItem>
                </StyledLink>
              )}
              {numSelected === 1 && (
                <MenuItem onClick={shipmentRequestOpen}>
                  <ListItemText size="large" primary="Send Shipment Request" />
                </MenuItem>
              )}
              <MenuItem
                aria-owns={dropdowncategory ? "category-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickCategory}
              >
                <ListItemText size="large" primary="Category" />
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                aria-owns={slocation ? "storage-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickSLocation}
              >
                <ListItemText size="large" primary="Storage Location" />
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
              </MenuItem>
              <MenuItem
                aria-owns={followupdrop ? "followup-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickFollowup}
              >
                <ListItemText size="large" primary="Followup" />
                <ListItemIcon>
                  <ArrowRightIcon />
                </ListItemIcon>
              </MenuItem>
              {numSelected === 1 && (
                <MenuItem aria-haspopup="true" onClick={handleCloneOpen}>
                  <ListItemText size="large" primary="Clone Record" />
                </MenuItem>
              )}
              {numSelected > 0 && selectedIdDS === 0 && (
                <MenuItem
                  aria-owns={disposeddrop ? "disposed-dropdown" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickDisposed}
                >
                  <ListItemText size="large" primary="Dispose" />
                  <ListItemIcon>
                    <ArrowRightIcon />
                  </ListItemIcon>
                </MenuItem>
              )}
              {numSelected > 1 && (
                <MenuItem onClick={createMergedGroupOpen}>
                  <ListItemText size="large" primary="Merge Group" />
                </MenuItem>
              )}
              {numSelected === 2 && (
                <MenuItem onClick={mergeDuplicatesOpen}>
                  <ListItemText size="large" primary="Merge Duplicates" />
                </MenuItem>
              )}
              {numSelected === 1 && (
                <MenuItem onClick={subItemsOpen}>
                  <ListItemText size="large" primary="Sub Items" />
                </MenuItem>
              )}
            </ActionsMenu>
            <SubMenu
              id="category-dropdown"
              anchorEl={dropdowncategory}
              open={Boolean(dropdowncategory)}
              onClose={handleCloseCategory}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {itemcatglist.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => updateRecordsvalue("category", item.value)}
                  >
                    <ListItemText size="large" primary={item.key} />
                  </MenuItem>
                );
              })}
            </SubMenu>
            <SubMenu
              id="category-dropdown"
              anchorEl={slocation}
              open={Boolean(slocation)}
              onClose={handleCloseSLocation}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {storageLocation.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() =>
                      updateRecordsvalue("storage_location", item.value)
                    }
                  >
                    <ListItemText size="large" primary={item.key} />
                  </MenuItem>
                );
              })}
            </SubMenu>
            <SubMenu
              id="followup-dropdown"
              anchorEl={followupdrop}
              open={Boolean(followupdrop)}
              onClose={handleCloseFollowup}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                onClick={() => updateRecordsvalue("followup_required", "Y")}
              >
                <ListItemText size="large" primary="Yes" />
              </MenuItem>
              <MenuItem
                onClick={() => updateRecordsvalue("followup_required", "N")}
              >
                <ListItemText size="large" primary="No" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              id="disposed-dropdown"
              anchorEl={disposeddrop}
              open={Boolean(disposeddrop)}
              onClose={handleCloseDisposed}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => disposeUpdate("Item donated")}>
                <ListItemText size="large" primary="Item donated" />
              </MenuItem>
              <MenuItem onClick={() => disposeUpdate("Item thrown away")}>
                <ListItemText size="large" primary="Item thrown away" />
              </MenuItem>
              <MenuItem onClick={() => disposeUpdate("Item destroyed")}>
                <ListItemText size="large" primary="Item destroyed" />
              </MenuItem>
            </SubMenu>

            {createmerge && (
              <MergeGroup
                showModal={createmerge}
                handleModal={(status) => setCreateMergedOpen(status)}
                selectedRecords={selectedRecords}
                showAlertTost={(msg, type) => showAlertTost(msg, type)}
                reloadList={() => reloadList()}
                handleActionsClose={() => handleActionsClose()}
                resetSelected={resetSelected}
              />
            )}

            {mergeduplicates && (
              <MergeDuplicates
                showModal={mergeduplicates}
                handleModal={(status) => setMergeDuplicates(status)}
                selectedRecords={selectedRecords}
                showAlertTost={(msg, type) => showAlertTost(msg, type)}
                reloadList={() => reloadList()}
                handleActionsClose={() => handleActionsClose()}
                resetSelected={resetSelected}
              />
            )}

            {shipmentrequest && (
              <ShipmentRequest
                showModal={shipmentrequest}
                handleModal={(status) => setShipmentRequest(status)}
                selectedRecords={selectedRecords}
                showAlertTost={(msg, type) => showAlertTost(msg, type)}
                reloadList={() => reloadList()}
                handleActionsClose={() => handleActionsClose()}
                resetSelected={resetSelected}
                selectedRecordId={selectedRecordId}
              />
            )}
            {scanbarcode && (
              <FindRecord
                show={scanbarcode}
                handleModal={(status) => setScanBarcode(status)}
                showAlertTost={(msg, type) => showAlertTost(msg, type)}
              />
            )}
          </Paper>
        </div>
        <div>
          <Typography
            color="inherit"
            variant="subtitle1"
            mt={3}
            style={{ width: "max-content", marginLeft: "15px" }}
          >
            {totalRecordinDb} records found
          </Typography>
        </div>
        <Spacer />
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={togglefilter ? "filter-items" : undefined}
              aria-haspopup="true"
              onClick={handleToggleClick}
              variant="contained"
              color="secondary"
              sx={{
                "& svg": {
                  width: 20,
                  height: 20,
                },
              }}
            >
              <Filter />
            </IconButton>
            <StyledFilter
              id="filter-items"
              anchorEl={togglefilter}
              open={Boolean(togglefilter)}
              onClose={handleToggleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {filterFormLoading === false ? (
                <Box textAlign="center" sx={{ padding: "15px" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    setFieldValue,
                    resetForm,
                    values,
                    status,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Card>
                        <CardHeader
                          action={
                            <Box className={classes.cardHeader}>
                              <Button
                                color="primary"
                                variant="outlined"
                                onClick={(e) => {
                                  setFilterFormLoading(false);
                                  resetForm();
                                  filterRecord({});
                                  setTimeout(() => {
                                    setFilterFormLoading(true);
                                  }, 1000);
                                }}
                              >
                                Clear
                              </Button>
                              <Typography
                                component="h3"
                                variant="span"
                                gutterBottom
                              >
                                Filters
                              </Typography>
                              <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                              >
                                Done
                              </Button>
                            </Box>
                          }
                        />
                        <CardContent>
                          <Accordion expanded={values.checkbox_desc === true}>
                            <AccordionSummary
                              aria-controls="description"
                              id="description"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_desc}
                                    name="checkbox_desc"
                                    value={values.checkbox_desc}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_desc",
                                        !values.checkbox_desc
                                      );
                                      updateAlphabetsFilter({
                                        checkbox_desc: !values.checkbox_desc,
                                      });
                                    }}
                                  />
                                }
                                label="Descrption"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomTextField
                                  name="description"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Enter description"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_category === true}
                          >
                            <AccordionSummary
                              aria-controls="category"
                              id="category"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_category}
                                    name="checkbox_category"
                                    value={values.checkbox_category}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_category",
                                        !values.checkbox_category
                                      );
                                      updateAlphabetsFilter({
                                        checkbox_category:
                                          !values.checkbox_category,
                                      });
                                    }}
                                  />
                                }
                                label="Category"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomSelect
                                  name="category"
                                  options={itemcatglist}
                                  displayEmpty
                                  hideFirstOption={false}
                                  firstOptionTitle="Select a category"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_found_date === true}
                          >
                            <AccordionSummary
                              aria-controls="found-date"
                              id="found-date"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_found_date}
                                    name="checkbox_found_date"
                                    value={values.checkbox_found_date}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_found_date",
                                        !values.checkbox_found_date
                                      );
                                    }}
                                  />
                                }
                                label="Found Date"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomSelect
                                  name="found_date_filter_type"
                                  options={date_filter_type}
                                  displayEmpty
                                  hideFirstOption={false}
                                  firstOptionTitle="Select a found date"
                                />
                              </FormControl>
                              <FormControl
                                mt={3}
                                style={{
                                  display:
                                    values.found_date_filter_type ===
                                    "is_in_the_last"
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <Box className={classes.itemInSingleRow} mt={3}>
                                  <Item>
                                    <SubdirectoryArrowRightIcon />
                                  </Item>
                                  <Item>
                                    <CustomTextField
                                      name="found_date_in_the_last_value"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Item>
                                  <Item>
                                    <CustomSelect
                                      name="found_date_in_the_last_type"
                                      options={[
                                        { value: "days", key: "Days" },
                                        { value: "months", key: "Months" },
                                      ]}
                                      displayEmpty
                                      hideFirstOption={true}
                                    />
                                  </Item>
                                </Box>
                              </FormControl>
                              <FormControl
                                mt={3}
                                style={{
                                  display: [
                                    "is_equal_to",
                                    "is_after",
                                    "is_on_or_after",
                                    "is_before",
                                    "is_before_or_on",
                                  ].includes(values.found_date_filter_type)
                                    ? "flex"
                                    : "none",
                                }}
                              >
                                <DatePicker
                                  label=""
                                  value={values.found_date ?? ""}
                                  name="found_date"
                                  onChange={(value) => {
                                    let date = value ? moment(value) : "";
                                    let dateValue = date
                                      ? date.format("YYYY-MM-DD")
                                      : "";
                                    setFieldValue("found_date", dateValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </FormControl>
                              <FormControl
                                mt={3}
                                style={{
                                  display:
                                    values.found_date_filter_type ===
                                    "is_between"
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <Box className={classes.itemInSingleRow}>
                                  <Item>
                                    <DatePicker
                                      label=""
                                      value={values.found_from_date ?? ""}
                                      name="found_from_date"
                                      onChange={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD")
                                          : "";
                                        setFieldValue(
                                          "found_from_date",
                                          dateValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Item>
                                  <Item align="center">
                                    <Typography>and</Typography>
                                  </Item>
                                  <Item>
                                    <DatePicker
                                      label=""
                                      value={values.found_to_date ?? ""}
                                      name="found_to_date"
                                      onChange={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD")
                                          : "";
                                        setFieldValue(
                                          "found_to_date",
                                          dateValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Item>
                                </Box>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_lost_date === true}
                          >
                            <AccordionSummary
                              aria-controls="lost-date"
                              id="lost-date"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_lost_date}
                                    name="checkbox_lost_date"
                                    value={values.checkbox_lost_date}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_lost_date",
                                        !values.checkbox_lost_date
                                      );
                                    }}
                                  />
                                }
                                label=" Lost Date"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomSelect
                                  name="lost_date_filter_type"
                                  options={date_filter_type}
                                  displayEmpty
                                  hideFirstOption={false}
                                  firstOptionTitle="Select a lost date"
                                />
                              </FormControl>
                              <FormControl
                                mt={3}
                                style={{
                                  display:
                                    values.lost_date_filter_type ===
                                    "is_in_the_last"
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <Box className={classes.itemInSingleRow} mt={3}>
                                  <Item>
                                    <SubdirectoryArrowRightIcon />
                                  </Item>
                                  <Item>
                                    <CustomTextField
                                      name="lost_date_in_the_last_value"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  </Item>
                                  <Item>
                                    <CustomSelect
                                      name="lost_date_in_the_last_type"
                                      options={[
                                        { value: "days", key: "Days" },
                                        { value: "months", key: "Months" },
                                      ]}
                                      displayEmpty
                                      hideFirstOption={true}
                                    />
                                  </Item>
                                </Box>
                              </FormControl>
                              <FormControl
                                mt={3}
                                style={{
                                  display: [
                                    "is_equal_to",
                                    "is_after",
                                    "is_on_or_after",
                                    "is_before",
                                    "is_before_or_on",
                                  ].includes(values.lost_date_filter_type)
                                    ? "flex"
                                    : "none",
                                }}
                              >
                                <DatePicker
                                  label=""
                                  value={values.lost_date ?? ""}
                                  name="lost_date"
                                  onChange={(value) => {
                                    let date = value ? moment(value) : "";
                                    let dateValue = date
                                      ? date.format("YYYY-MM-DD")
                                      : "";
                                    setFieldValue("lost_date", dateValue);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </FormControl>
                              <FormControl
                                mt={3}
                                style={{
                                  display:
                                    values.lost_date_filter_type ===
                                    "is_between"
                                      ? "flex"
                                      : "none",
                                }}
                              >
                                <Box className={classes.itemInSingleRow}>
                                  <Item>
                                    <DatePicker
                                      label=""
                                      value={values.lost_from_date ?? ""}
                                      name="lost_from_date"
                                      onChange={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD")
                                          : "";
                                        setFieldValue(
                                          "lost_from_date",
                                          dateValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Item>
                                  <Item align="center">
                                    <Typography>and</Typography>
                                  </Item>
                                  <Item>
                                    <DatePicker
                                      label=""
                                      value={values.lost_to_date ?? ""}
                                      name="lost_to_date"
                                      onChange={(value) => {
                                        let date = value ? moment(value) : "";
                                        let dateValue = date
                                          ? date.format("YYYY-MM-DD")
                                          : "";
                                        setFieldValue(
                                          "lost_to_date",
                                          dateValue
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                    />
                                  </Item>
                                </Box>
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_lost_last_name === true}
                          >
                            <AccordionSummary
                              aria-controls="lost-last-name"
                              id="lost-last-name"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_lost_last_name}
                                    name="checkbox_lost_last_name"
                                    value={values.checkbox_lost_last_name}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_lost_last_name",
                                        !values.checkbox_lost_last_name
                                      );
                                      updateAlphabetsFilter({
                                        checkbox_lost_last_name:
                                          !values.checkbox_lost_last_name,
                                      });
                                    }}
                                  />
                                }
                                label="Lost Last Name"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomTextField
                                  name="lost_last_name"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Enter lost last name"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_found_location === true}
                          >
                            <AccordionSummary
                              aria-controls="found-loc"
                              id="found-loc"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_found_location}
                                    name="checkbox_found_location"
                                    value={values.checkbox_found_location}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_found_location",
                                        !values.checkbox_found_location
                                      );
                                      updateAlphabetsFilter({
                                        checkbox_found_location:
                                          !values.checkbox_found_location,
                                      });
                                    }}
                                  />
                                }
                                label="Found Location"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomTextField
                                  name="found_location"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Enter found location"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_store_location === true}
                          >
                            <AccordionSummary
                              aria-controls="storage-loc"
                              id="storage-loc"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_store_location}
                                    name="checkbox_store_location"
                                    value={values.checkbox_store_location}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_store_location",
                                        !values.checkbox_store_location
                                      );
                                      updateAlphabetsFilter({
                                        checkbox_store_location:
                                          !values.checkbox_store_location,
                                      });
                                    }}
                                  />
                                }
                                label="Storage Location"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomSelect
                                  name="storage_location"
                                  options={storageLocation}
                                  displayEmpty
                                  hideFirstOption={false}
                                  firstOptionTitle="Select a storage location"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                          <Accordion
                            expanded={values.checkbox_control_id === true}
                          >
                            <AccordionSummary
                              aria-controls="control-id"
                              id="control-id"
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values.checkbox_control_id}
                                    name="checkbox_control_id"
                                    value={values.checkbox_control_id}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "checkbox_control_id",
                                        !values.checkbox_control_id
                                      );
                                    }}
                                  />
                                }
                                label="Control ID"
                              />
                            </AccordionSummary>
                            <AccordionDetails>
                              <FormControl>
                                <CustomTextField
                                  name="id"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  placeholder="Enter control ID"
                                />
                              </FormControl>
                            </AccordionDetails>
                          </Accordion>
                        </CardContent>
                      </Card>
                    </form>
                  )}
                </Formik>
              )}
            </StyledFilter>
          </Paper>
        </div>
        <div>
          <Paper mt={3}>
            <IconButton
              aria-owns={anchorEl ? "icon-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              <FilterListIcon />
            </IconButton>
            <StyledFilterMenu
              id="icon-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem component={NavLink} to="/lostandfound/add-lost-found">
                <ListItemIcon>
                  <AddBoxIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Add Lost/Found" pl={0} />
              </MenuItem>
              <MenuItem component={NavLink} to="/lostandfound/match-lost-found">
                <ListItemIcon>
                  <CompareIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Match Lost/Found" pl={0} />
              </MenuItem>
              <MenuItem onClick={() => setScanBarcode(true)}>
                <ListItemIcon>
                  <SettingsOverscanIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="Scan Bag Tag" pl={0} />
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/lostandfound/view-activity-logs"
              >
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText size="large" primary="View Activity Log" pl={0} />
              </MenuItem>
              <MenuItem
                aria-owns={anchorColumn ? "columns-dropdown" : undefined}
                aria-haspopup="true"
                onClick={handleClickColumn}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Columns" pl={0} />
              </MenuItem>
            </StyledFilterMenu>
            <StyledColumnDropdown
              id="columns-dropdown"
              anchorEl={anchorColumn}
              open={Boolean(anchorColumn)}
              onClose={handleCloseColumn}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>
                <Card>
                  <CardContent>
                    <Typography variant="h6" component="h2">
                      {
                        Object.keys(lostFoundListing).filter(
                          (k) => lostFoundListing[k] === true
                        ).length
                      }{" "}
                      out of {Object.keys(lostFoundListing).length} visible
                    </Typography>
                    <StyledColumnOptions
                      calStatus={lostFoundListing}
                      updateCalStatus={(data) => {
                        dispatch(setLostFoundListing(data));
                      }}
                      listing={LostFoundListingCol}
                    />
                    <Toolbar>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          dispatch(setLostFoundListing(defaultTableCalRedux));
                        }}
                      >
                        Reset
                      </Button>
                      <Spacer />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleCloseColumn}
                      >
                        Cancel
                      </Button>
                    </Toolbar>
                  </CardContent>
                </Card>
              </MenuItem>
            </StyledColumnDropdown>
          </Paper>
        </div>
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "600px",
              },
            },
          }}
          open={open}
          onClose={lostFoundModalClose}
          aria-labelledby="visitor-modal"
          aria-describedby="visitor-modal"
        >
          <DialogTitle
            id="visitor-modal"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingBottom: "0px",
            }}
          >
            <DialogActions sx={{ padding: "0px" }}>
              <IconButton
                sx={{ marginRight: "-10px" }}
                onClick={() => lostFoundModal(false)}
              >
                <CancelIcon />
              </IconButton>
            </DialogActions>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px" }}>
            <DialogContentText sx={{ padding: "0px 20px 20px" }}>
              <Box align="center">
                <Typography variant="h4" component="div" mt={3} align="center">
                  Add Lost and Found Item
                </Typography>
                <Typography variant="p" component="div" mt={3} align="center">
                  Are you adding a Lost or a Found item?
                </Typography>
              </Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{ justifyContent: "center", paddingBottom: "30px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              component={NavLink}
              to="/lostandfound/add-lost-found"
            >
              Lost
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginLeft: "10px" }}
              component={NavLink}
              to="/lostandfound/add-found"
            >
              Found
            </Button>
          </DialogActions>
        </Dialog>
      </Toolbar>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}

      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={prievewImage}
        onClose={prievewImageClose}
        aria-labelledby="viewmap-title"
        aria-describedby="viewmap-description"
      >
        <DialogTitle
          id="viewmap-title"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "0px",
          }}
        >
          <Typography variant="h4">Prievew Image</Typography>
          <DialogActions sx={{ padding: "0px" }}>
            <IconButton
              sx={{ marginRight: "-10px" }}
              onClick={() => setPrievewImage(false)}
            >
              <CancelIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Image
              src={selectedRecordData?.[0]?.image}
              alt={selectedRecordData?.[0]?.image}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "20px 24px" }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={prievewImageClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
        open={cloneLostFound}
        onClose={CloseLostFoundClone}
      >
        <DialogTitle>Clone Lost and Found Record</DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            This action will clone the existing lost and found record. This
            generally is only necessary if a shipping transaction needs to be
            changed, for example, a guest who has just paid for shipping wants
            to have their item shipped to a different address.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClose={CloseLostFoundClone}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClone}
            disabled={loading} // Disable the button while loading
          >
            {loading ? <CircularProgress size={24} /> : "Clone"}
          </Button>
        </DialogActions>
      </Dialog>
      {showAlert && (
        <CustomAlert
          message={alertMessage}
          severity={alertType}
          closeAlert={(show) => setShowAlert(show)}
          showAlert={showAlert}
        />
      )}
    </React.Fragment>
  );
};

export default EnhancedTableToolbar;
