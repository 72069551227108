import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPermissions } from "../redux/slices/permissionSlice";
import { Box, Typography, Button, Modal } from "@mui/material";

const WithPermissionFallback = ({ controller, action, fallback, children }) => {
  const dispatch = useDispatch();

  // Fetch current user's role id
  const currentUser = useSelector((state) => state.login.user);

  // Fetch permissions from the store
  const { permissions, loading, error } = useSelector(
    (state) => state.permissions
  );

  const handleGoBack = () => {
    window.location.href = "https://sova-front.demoserver.in"; // Redirect to the specified URL
  };

  // Fetch permissions if they are not loaded yet
  useEffect(() => {
    if (Object.keys(permissions).length === 0) {
      dispatch(fetchPermissions());
    }
  }, [dispatch, permissions]);

  // Show loading state while fetching permissions
  if (loading || !currentUser) {
    // return <div>Loading permissions...</div>; // Optional loading UI
    return null;
  }

  // Handle any error that occurred during permission fetch
  if (error) {
    // return <div>Error loading permissions: {error}</div>; // Show error
    return null;
  }

  // Check if permissions exist for the specified action within the controller
  const actionPermissions =
    permissions.length &&
    permissions.find(
      (perm) => perm.controller === controller && perm.action_name === action
    );

  // If no permission object is found or the user group array is empty, render fallback UI
  if (!actionPermissions || actionPermissions.user_group.length === 0) {
    return (
      <Modal open={true} onClose={handleGoBack} sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            m: "auto",
          }}
        >
          <Typography variant="h6">Access Denied</Typography>
          <Typography sx={{ mt: 2 }}>
            You do not have permission to access this resource.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            sx={{ mt: 2 }}
          >
            Go Back
          </Button>
        </Box>
      </Modal>
    ); // Render fallback UI
  }

  // Check if the user's group is included in the allowed user groups
  if (!actionPermissions.user_group.includes(currentUser?.user_group_id)) {
    return (
      <Modal open={true} onClose={handleGoBack} sx={{ display: "flex" }}>
        <Box
          sx={{
            width: 300,
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            m: "auto",
          }}
        >
          <Typography variant="h6">Access Denied</Typography>
          <Typography sx={{ mt: 2 }}>
            You do not have permission to access this resource.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoBack}
            sx={{ mt: 2 }}
          >
            Go Back
          </Button>
        </Box>
      </Modal>
    ); // User does not have permission, render fallback UI
  }

  // If all checks pass, render the children (the component that requires permission)
  return children;
};

export default WithPermissionFallback;
