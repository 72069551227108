import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  TextField,
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import {
  CancelRounded as CancelRoundedIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import { AddItemCategoryList } from "../../../api";
import { Tooltip } from "@mui/material";

import {
  getMsg,
  getSnackAlert,
  getSnackClose,
  getSnackOpen,
} from "../../../redux/slices/mainSlice";
import { useDispatch } from "react-redux";
import { Check } from "react-feather";

// Classes Styles
const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00000059",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
}));

const ItemCategories = ({
  itemCategoriesDialog,
  itemCategoriesDialogClose,
  getCategoryList,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null); // To store index of no_ship_reason being edited
  const [noShipReason, setNoShipReason] = useState("");
  const [fieldValue, setFieldValue] = useState({
    key: "",
    value: {
      weight: "",
      type: "",
      no_ship: 0, // Initialize no_ship as 0
      no_ship_reason: null, // Initialize no_ship_reason as null
    },
  });

  const [fields, setFields] = useState([]);

  const handleChange = (name, value) => {
    const field = {
      key: fieldValue.key,
      value: {
        weight: fieldValue.value.weight,
        type: fieldValue.value.type,
      },
    };
    if (name === "key") {
      field[name] = value;
    } else {
      field.value[name] = value;
    }
    setFieldValue(field);
  };
  const handleNoShipChange = (index, checked) => {
    const data = [...fields];

    data[index].value.no_ship = checked ? 1 : 0; // Set no_ship to 1 when checked, else 0

    if (checked) {
      data[index].value.weight = null; // Set weight to null when no_ship is checked
      data[index].value.type = null; // Set type to null when no_ship is checked
      setFields(data); // Update the fields state
      setSelectedIndex(index); // Store the index for which we are adding no_ship_reason
      setOpenDialog(true); // Open dialog to enter no_ship_reason
    } else {
      data[index].value.weight = ""; // Reset weight when unchecked
      data[index].value.type = ""; // Reset type when unchecked
      data[index].value.no_ship_reason = null; // Reset reason when unchecked
      setFields(data); // Update the fields state
    }
  };

  const handleEdit = (name, value, index) => {
    const data = [...fields];
    if (name === "key") {
      data[index][name] = value;
      setFields(data);
    } else {
      data[index].value[name] = value;
      setFields(data);
    }
  };

  const addItemCategoryList = () => {
    setLoading(true);

    const payload = {
      description: fields,
    };
    AddItemCategoryList(payload)
      .then((res) => {
        if (res.statusCode == 200) {
          dispatch(getMsg("Records Updated Successfully"));
          dispatch(getSnackAlert("success"));
          dispatch(getSnackOpen());
          getCategoryList();
          setLoading(false);
          itemCategoriesDialogClose();
        } else {
          dispatch(getMsg("Something Went Wrong!"));
          dispatch(getSnackAlert("erorr"));
          dispatch(getSnackOpen());
          setFields(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getItemCategoryList = () => {
    setLoading(true);
    AddItemCategoryList(fields)
      .then((res) => {
        if (res.statusCode == 200) {
          setFields(res.data);
        } else {
          setFields(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getItemCategoryList();
  }, []);

  const handleDelete = (index) => {
    setFields(fields.filter((item, itemIndex) => itemIndex !== index));
  };
  const handleSaveReason = () => {
    const updatedFields = fields.map((field, index) =>
      index === selectedIndex
        ? {
            ...field,
            value: {
              ...field.value,
              no_ship: 1, // Ensure no_ship is set to 1
              no_ship_reason: noShipReason || null, // Save the no_ship_reason or null
            },
          }
        : field
    );

    setFields(updatedFields); // Update the fields state
    handleDialogClose(); // Close the dialog after saving
    setNoShipReason(""); // Clear the input after saving
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setNoShipReason(""); // Clear the input when dialog is closed
  };
  return (
    <>
      <Dialog
        aria-labelledby="lift-out-title-modal"
        aria-describedby="lift-out-modal"
        open={itemCategoriesDialog}
        onClose={itemCategoriesDialogClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#E8E8E8",
          }}
          id="lift-out-title"
        >
          <Typography variant="h4" component="div" gutterBottom>
            Item Categories
          </Typography>
          <DialogActions>
            <IconButton
              onClick={itemCategoriesDialogClose}
              sx={{ marginRight: "-10px" }}
            >
              <CancelRoundedIcon />
            </IconButton>
          </DialogActions>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ padding: "20px" }}>
            Categories for your lost and found items are shown below. Default
            categories for Cash, Cell Phone, Documents, Driver License/ID,
            Jewelry, Laptop and Passport do not appear below but are already
            added to your portal so please do not add those. After entering a
            category name in a blank row below, set an average weight for the
            item then choose the weight scale. Use whole numbers only when
            setting the average weight.
          </Typography>
          <TableContainer
            className="tableContainer"
            component={Paper}
            sx={{
              margin: "15px auto auto",
              "&::-webkit-scrollbar": {
                width: 7,
                height: 7,
              },
              "&::-webkit-scrollbar-track": {
                background: "#bed2f5",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#92a6c9",
              },
            }}
          >
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size={true ? "small" : "medium"}
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* <Checkbox
                        checked={disableWeight}
                        onChange={(e) => setDisableWeight(e.target.checked)} // Toggling disable state for Avg. Weight input
                      /> */}
                      No Ship
                      <Tooltip
                        title="Mark the category as not able to be shipped. This is useful for categories such as alcohol, cash, passports, etc."
                        arrow
                      >
                        <IconButton>
                          <InfoIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="left">Avg. Weight</TableCell>
                  <TableCell align="left">Scale</TableCell>
                  <TableCell align="left">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <TextField
                        name="key"
                        required
                        id={`outlined-required-${index}`}
                        value={item?.key}
                        variant="outlined"
                        placeholder="Enter Category"
                        onChange={(event) => {
                          handleEdit(
                            event.target.name,
                            event.target.value,
                            index
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" sx={{ borderBottom: "none" }}>
                      <Checkbox
                        checked={item.value.no_ship === 1} // Controlled checkbox
                        onChange={(e) =>
                          handleNoShipChange(index, e.target.checked)
                        } // Call handleNoShipChange when toggled
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <TextField
                        name="weight"
                        required
                        id={`outlined-weight-${index}`}
                        value={item?.value?.weight}
                        variant="outlined"
                        placeholder="Enter Weight"
                        type="number"
                        disabled={item.value.no_ship === 1}
                        onChange={(event) => {
                          handleEdit(
                            event.target.name,
                            event.target.value,
                            index
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      <TextField
                        select
                        variant="outlined"
                        name="type"
                        sx={{ width: "90px" }}
                        disabled={item.value.no_ship === 1}
                        value={
                          item?.value?.type !== "" && item?.value?.type !== null
                            ? item?.value?.type
                            : "select"
                        }
                        onChange={(event) => {
                          handleEdit(
                            event.target.name,
                            event.target.value,
                            index
                          );
                        }}
                      >
                        <MenuItem value="select" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="g">g</MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="lb">lbs</MenuItem>
                        <MenuItem value="oz">oz</MenuItem>
                      </TextField>
                    </TableCell>
                    <TableCell align="left" sx={{ borderBottom: "none" }}>
                      {index == fields.length - 1 ? (
                        <AddCircleIcon
                          sx={{ cursor: "pointer" }}
                          color="primary"
                          onClick={() => {
                            setFields([...fields, fieldValue]);
                            setFieldValue({
                              key: "",
                              value: {
                                weight: "",
                                type: "",
                              },
                            });
                          }}
                        />
                      ) : (
                        <DoDisturbOnIcon
                          sx={{ cursor: "pointer" }}
                          color="error"
                          onClick={() => {
                            handleDelete(index);
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                {/* <TableRow>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <TextField
                      name="key"
                      required
                      id={`outlined-required`}
                      placeholder="Enter Category"
                      variant="outlined"
                      value={fieldValue?.key}
                      onChange={(event) => {
                        handleChange(event.target.name, event.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <TextField
                      name="weight"
                      required
                      id={`outlined-weight`}
                      placeholder="Enter Weight"
                      variant="outlined"
                      type="number"
                      value={fieldValue?.value?.weight}
                      onChange={(event) => {
                        handleChange(event.target.name, event.target.value);
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <TextField
                      select
                      name="type"
                      variant="outlined"
                      sx={{ width: "90px" }}
                      value={
                        fieldValue?.value?.type !== ""
                          ? fieldValue?.value?.type
                          : "select"
                      }
                      onChange={(event) => {
                        handleChange(event.target.name, event.target.value);
                      }}
                    >
                      <MenuItem value="select" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="g">g</MenuItem>
                      <MenuItem value="kg">kg</MenuItem>
                      <MenuItem value="lb">lbs</MenuItem>
                      <MenuItem value="oz">oz</MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell align="left" sx={{ borderBottom: "none" }}>
                    <AddCircleIcon
                      sx={{ cursor: "pointer" }}
                      color="primary"
                      onClick={() => {
                        setFields([...fields, fieldValue]);
                        setFieldValue({
                          key: "",
                          value: {
                            weight: "",
                            type: "",
                          },
                        });
                      }}
                    />
                  </TableCell>
                </TableRow> */}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={{ pr: "28px" }}>
          <Button
            color="warning"
            variant="outlined"
            onClick={() => {
              addItemCategoryList();
            }}
          >
            Submit
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={itemCategoriesDialogClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <Paper className={classes.fixedLoader}>
          <CircularProgress />
        </Paper>
      )}
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px",
            },
          },
        }}
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle
          sx={{ justifyContent: "end", display: "flex", padding: "0px" }}
        >
          <IconButton onClick={handleDialogClose}>
            <CancelRoundedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="No Ship Reason"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={4}
            sx={{
              marginTop: "15px",
            }}
            value={noShipReason}
            onChange={(e) => setNoShipReason(e.target.value)}
            placeholder="Provide an explanation why this category cannot be shipped"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={handleSaveReason}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemCategories;
